import React, { useContext, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Container, Row, Col } from 'react-bootstrap';

// Context
import GlobalContext from 'contexts/Global.context';

import { APP_CONSTANTS } from 'constants/Constants';

import ExistingCards from './ExistingCards/ExistingCards';

import { fetchCardsService } from 'services/CardService';

import styles from './PaymentForm.module.scss';

interface PaymentFormProps {
  setSelectedCard: (cardId: string) => void;
}

const { stripeKey } = APP_CONSTANTS;

const STRIPE_LOAD = loadStripe(stripeKey);

const PaymentForm: React.FunctionComponent<PaymentFormProps> = ({
  setSelectedCard,
}: PaymentFormProps) => {
  const globalContext = useContext(GlobalContext);

  const {
    userDetails: { paymentId },
  } = globalContext;

  useEffect(() => {
    const fetchCards = async () => {
      const { sources } = await fetchCardsService();
      if (sources && sources.data && sources.data.length) {
        setSelectedCard(sources.data[0].id);
      }
    };
    if (paymentId) {
      fetchCards();
    }
  }, [paymentId, setSelectedCard]);

  return (
    <Elements stripe={STRIPE_LOAD}>
      <Container id="checkoutForm">
        <Row className={styles.cardsWrapper}>
          <Col xl={12} lg={12} md={12} sm={12}>
            <ExistingCards cardSelectedForPayment={setSelectedCard} />
          </Col>
        </Row>
      </Container>
    </Elements>
  );
};

export default React.memo(PaymentForm);
