import React, { useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

// Page Components
import Button from 'components/Button/Button';
import Result from '../../../../JobBoard/Result/Result';
import Loader from 'components/Loader/Loader';

// Interfaces
import { Job } from 'interfaces/Job.types';

// Constants
import { ITEMS_PER_PAGE } from 'constants/Constants';

// SCSS
import styles from './Listing.module.scss';

interface ListingType {
  type: string;
  loading?: boolean;
  jobs: Array<Job> | null;
  totalJobs: number | null;
  fetchMoreJobs: (page: number) => void;
  error?: string;
}

const Listing = ({
  jobs,
  totalJobs,
  type,
  loading,
  fetchMoreJobs,
  error,
}: ListingType) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchMore = () => {
    setCurrentPage(currentPage + 1);
    fetchMoreJobs(currentPage + 1);
  };

  return (
    <div className={styles.searchResults}>
      {jobs &&
        jobs.map((job) => (
          <Result
            key={job.id}
            id={job.id}
            image={job.company_logo || 'https://placehold.co/70x70'}
            title={job.title}
            category={job.category}
            location={job.location}
            type={job.expected_need}
            jobType={type}
            date={job.created_at}
            isFeatured={job.is_featured}
            city={job.city}
            state={job.state}
          />
        ))}

      {loading && <Loader />}

      {jobs && jobs.length === 0 && !loading && (
        <Alert variant="primary">No Jobs Found</Alert>
      )}
      {!jobs && !loading && error && <Alert variant="danger">{error}</Alert>}

      {!loading &&
      totalJobs &&
      totalJobs > ITEMS_PER_PAGE &&
      totalJobs !== jobs?.length ? (
        <Row>
          <Col
            className={`${styles.listingViewAll}`}
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Button type="large" label="View More" onClick={fetchMore} />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default React.memo(Listing);
