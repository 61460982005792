import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFileAlt } from '@fortawesome/free-solid-svg-icons';

// Components
import Button from '../../../components/Button/Button';

// Constants
import { ROUTES } from 'constants/Constants';

// SCSS
import styles from './Applicants.module.scss';

// Props
interface Applicants {
  jobId?: string;
  note?: string;
  applicationId: string;
  is_interested: boolean;
  is_hired: boolean;
  userId?: string;
  image: string | 'https://placehold.co/70x70';
  title: string;
  location: string;
  phone: string;
  email: string;
  slug?: string;
  resume: string | null;
  isTopCandidate?: boolean;
  markNotInterested: (applicationId: string, isInterested: boolean) => void;
  markAsTopCandidate: (applicationId: string, isTopCandidate: boolean) => void;
  markAsHired: (jobId: string, applicationId: string, isHired: boolean) => void;
}

const Applicants = (props: Applicants) => {
  return (
    <Fragment>
      <Container
        className={`${styles.applicantsList} ${
          !props.is_interested ? styles.hired : ''
        }`}
      >
        <Row>
          {/* @dev add class "appHired" on "applicantsListWrapper div" class when clicked on Mark As Hired */}
          {/* @dev toogle hide show "Top Candidate" on click of Mark As Top Candidate button */}
          <Col className={styles.applicantsListWrapper}>
            <div className={styles.imgWrapper}>
              <Link to={`${ROUTES.PROFILE}/${props.slug}`}>
                <img alt={props.title} src={props.image} />
              </Link>
            </div>
            <div className={styles.appDetails}>
              <div className={styles.appName}>
                <Link to={`${ROUTES.PROFILE}/${props.slug}`}>
                  {props.title}
                </Link>
              </div>
              <div className={styles.appContact}>
                <span>{props.location}</span>
                <span>{props.phone}</span>
                <span>{props.email}</span>
              </div>
              <div className={styles.appAbout}>
                <div>
                  <Link to={`${ROUTES.PROFILE}/${props.slug}`}>
                    <FontAwesomeIcon icon={faUser} />
                    <span>View Profile</span>
                  </Link>
                </div>
                {props.resume ? (
                  <div>
                    <a
                      href={props.resume}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFileAlt} />
                      <span>View Submitted Documents</span>
                    </a>
                  </div>
                ) : null}
              </div>
              {props.note ? (
                <div className={styles.appAbout}>{props.note}</div>
              ) : (
                ''
              )}
            </div>
            {props.is_hired ? (
              <div className={styles.appStatus}>
                <Button label="Hired" type="green-check" />
              </div>
            ) : null}
            {props.isTopCandidate && !props.is_hired ? (
              <div className={styles.appStatus}>
                <Button label="Top Candidate" type="blue" />
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
      <Container className={styles.resultOptions}>
        <Row>
          <Col className={'p-0'}>
            <Button
              label={
                !props.is_interested ? 'I am interested' : 'Not Interested'
              }
              type="transparent outline-gray"
              /* disabled={!props.is_interested} */
              onClick={() =>
                props.markNotInterested(
                  props.applicationId,
                  !props.is_interested
                )
              }
            />
            <Button
              label={
                !props.isTopCandidate
                  ? 'Mark As Top Candidate'
                  : 'Remove As Top Candidate'
              }
              type="transparent outline-gray"
              onClick={() =>
                props.markAsTopCandidate(
                  props.applicationId,
                  !props.isTopCandidate
                )
              }
            />
            <Button
              label={!props.is_hired ? 'Mark As Hired' : 'Hired'}
              type="transparent outline-gray"
              onClick={() =>
                props.markAsHired(
                  props.jobId,
                  props.applicationId,
                  !props.is_hired
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Applicants;
