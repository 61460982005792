export const camelToSnake = (camelCase: string): string => {
  return camelCase
    .replace(/[\w]([A-Z])/g, (m) => {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
};

export const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/g, (group: string) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );

export const validateURL = (str: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const stringifyMe = (obj: any) => JSON.stringify(obj);

export const addParamsToURL = (obj: any) =>
  Object.keys(obj)
    .map((o) => `${encodeURIComponent(o)}=${encodeURIComponent(obj[o])}`)
    .join('&');
