import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

// Components
import Input from 'components/Form/Input/Input';
import Select from 'components/Form/Select/Select';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import TextArea from 'components/Form/TextArea/TextArea';
import Button from 'components/Button/Button';
import FileUpload from 'components/FileUpload/FileUpload';

import { Multiselect } from 'multiselect-react-dropdown';

import PaymentForm from 'components/PaymentForm/PaymentForm';

// Interfaces
import {
  OpportunityForm,
  OpportunityFormErrors,
} from 'interfaces/OpportunityForm.types';
import { Job } from 'interfaces/Job.types';
import { Category } from 'interfaces/Category.types';

// Services
import { createJobService, updateJobService } from 'services/JobService';
import {
  getUploadURLService,
  verifyCouponService,
} from 'services/CommonService';

// Utilities
import { camelToSnake } from 'utils/UtilityFunctions';

// Constants
import STATES from 'constants/States';
import INDUSTRIES from 'config/Industries';
import OPPORTUNITY_CONFIG from 'config/CreateOpportunityConfig';
import OPPORTUNITY_RULES from '../../constants/OpportunityRules';
import {
  DEFAULT_OPPORTUNITY_ERRORS,
  DEFAULT_OPPORTUNITY_VALUES,
} from '../../constants/DefaultValues';
import FIELD_LABELS from '../../constants/FieldLabels';
import { APP_CONSTANTS, FILE_TYPE, ROUTES } from 'constants/Constants';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './Opportunity.module.scss';

interface CouponResponse {
  id: string;
  amount: number;
}

interface PostProps {
  id: string;
  job: Job;
  categories: Array<Category>;
  relist: boolean;
}

const Post: React.FC<PostProps> = ({
  id: postId,
  job,
  categories,
  relist,
}: PostProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [logo, setLogo] = useState<File | null>(null);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [code, setCode] = useState<string>('');
  const [coupon, setCoupon] = useState<CouponResponse | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [couponError, setCouponError] = useState<string>('');

  const [opportunity, setOpportunity] = useState<OpportunityForm>(
    DEFAULT_OPPORTUNITY_VALUES
  );

  const [opportunityFormErrors, setOpportunityFormErrors] = useState<
    OpportunityFormErrors
  >(DEFAULT_OPPORTUNITY_ERRORS);

  const {
    errorConstants: { SOMETHING_WENT_WRONG: serverError },
    opportunityFeaturedAmount: featuredAmount,
    postCost,
  } = APP_CONSTANTS;

  const fileSpecs = ['5mb max', '500px wide', '500px tall'];

  useEffect(() => {
    if (job) {
      const obj: any = {};
      Object.keys(DEFAULT_OPPORTUNITY_VALUES).map((key: string) => {
        obj[key] = job[camelToSnake(key)];
        if (obj[key] === null) {
          obj[key] = '';
        }
        return key;
      });
      if (!obj.referenceLinks || !obj.referenceLinks.length) {
        obj.referenceLinks = ['', '', ''];
      }
      setOpportunity(obj);
      if (relist) {
        let amount = 0;
        if (obj.isFeatured) {
          amount += featuredAmount;
        }
        if (obj.fee) {
          amount += postCost;
        }
        setAmount(amount);
      }
    }
  }, [job, featuredAmount, postCost, relist]);

  const handleReferenceLink = (event: any, index: number) => {
    if (apiSuccess) {
      setApiSuccess(false);
      setJobId('');
    }
    if (couponError) {
      setCouponError('');
    }
    const { value, name } = event.target;
    const links = [...opportunity.referenceLinks];
    links[index] = value;
    setOpportunity({
      ...opportunity,
      referenceLinks: links,
    });

    if (value && !OPPORTUNITY_RULES.referenceLinks.pattern.test(value)) {
      const linkErrors = [...opportunityFormErrors.referenceLinks];
      linkErrors[index] = `${FIELD_LABELS[name]} is invalid`;
      setOpportunityFormErrors({
        ...opportunityFormErrors,
        referenceLinks: linkErrors,
      });
    }

    if (!value || OPPORTUNITY_RULES.referenceLinks.pattern.test(value)) {
      const linkErrors = [...opportunityFormErrors.referenceLinks];
      linkErrors[index] = '';
      setOpportunityFormErrors({
        ...opportunityFormErrors,
        referenceLinks: linkErrors,
      });
    }
  };

  const validateField = (target: any) => {
    const { name, value, type, checked } = target;
    if (type === 'checkbox') {
      if (!checked && OPPORTUNITY_RULES[name]?.required) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: '',
        });
      }
    } else {
      if (name === 'pay' && value < 0) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} can either be blank (non-paying) or greater than 0`,
        });
      } else if (name === 'fee' && value < 0) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} can either be blank (no-fee) or greater than 0`,
        });
      } else if (
        name === 'externalUrl' &&
        !value &&
        opportunity['applyThrough'] === 'external'
      ) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else if (OPPORTUNITY_RULES[name]?.required && !value) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else if (value) {
        if (
          OPPORTUNITY_RULES[name]?.minLength &&
          value.toString().length < OPPORTUNITY_RULES[name]?.minLength
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} should contain at least ${OPPORTUNITY_RULES[name]?.minLength} characters`,
          });
        } else if (
          OPPORTUNITY_RULES[name]?.maxLength &&
          value.toString().length > OPPORTUNITY_RULES[name]?.maxLength
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} can not be longer than ${OPPORTUNITY_RULES[name]?.maxLength} characters`,
          });
        } else if (
          OPPORTUNITY_RULES[name]?.min &&
          value < OPPORTUNITY_RULES[name]?.min
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} should be greater than ${OPPORTUNITY_RULES[name]?.min}`,
          });
        } else if (
          OPPORTUNITY_RULES[name]?.max &&
          value < OPPORTUNITY_RULES[name]?.max
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} should be lesser than ${OPPORTUNITY_RULES[name]?.max}`,
          });
        } else if (
          OPPORTUNITY_RULES[name]?.pattern &&
          !OPPORTUNITY_RULES[name]?.pattern.test(value)
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} is invalid`,
          });
        } else {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: '',
          });
        }
      } else {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: '',
        });
      }
    }
    return;
  };

  const handleCodeChange = (event: any) => {
    setCouponError('');
    setCode(event.target.value);
  };

  const handleFeatured = (val: boolean) => {
    if (apiSuccess) {
      setApiSuccess(false);
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    if (couponError) {
      setCouponError('');
    }
    setOpportunity({
      ...opportunity,
      isFeatured: val,
    });

    setOpportunityFormErrors({
      ...opportunityFormErrors,
      isFeatured: '',
    });

    let amount = val ? featuredAmount : 0;
    if (opportunity.fee) {
      amount += postCost;
    }

    setAmount(amount);
    setCoupon(null);
    setCode('');
  };

  const handleCategorySelect = (categoryList: Array<Category | any>) => {
    setOpportunity({
      ...opportunity,
      category: categoryList,
    });

    if (!categoryList.length) {
      setOpportunityFormErrors({
        ...opportunityFormErrors,
        category: 'Category is required',
      });
    } else {
      setOpportunityFormErrors({
        ...opportunityFormErrors,
        category: '',
      });
    }
  };

  const handleChange = (event: any) => {
    if (apiSuccess) {
      setApiSuccess(false);
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    const { name, value, type, checked } = event.target;
    if (type !== 'checkbox') {
      setOpportunity({
        ...opportunity,
        [name]:
          (name === 'pay' || name === 'fee') && value
            ? parseFloat(value)
            : value,
      });
      if (name === 'fee') {
        let amount =
          opportunity.isFeatured && (!postId || relist) ? featuredAmount : 0;
        if (value && parseFloat(value) && (!job || !job.fee)) {
          amount += postCost;
        }

        setAmount(amount);
        setCoupon(null);
        setCode('');
      }
    } else {
      setOpportunity({
        ...opportunity,
        [name]: checked ? value : '',
      });
    }
    validateField(event.target);
  };

  const uploadLogo = async () => {
    let uploadImageResponse: any = null;
    const fileInfo = {
      name: logo?.name,
      type: logo?.type,
    };
    const { signedRequest, url, error } = await getUploadURLService(fileInfo);

    if (signedRequest && url) {
      uploadImageResponse = await fetch(signedRequest, {
        method: 'PUT',
        body: logo,
      });

      if (uploadImageResponse) {
        return url;
      }
    } else if (error) {
      setApiError(`An error occurred while uploading logo: ${error}`);
      setIsLoading(false);
      return null;
    }
  };

  const verifyCouponCode = async () => {
    let amount = opportunity.isFeatured ? featuredAmount : 0;
    if (opportunity.fee) {
      amount += postCost;
    }
    if (coupon) {
      setCode('');
      setCoupon(null);
      setAmount(amount);
      return;
    }
    if (!code) {
      return;
    }
    setCouponError('');
    setIsLoading(true);
    const { id, type, error } = await verifyCouponService(code);

    if (id) {
      setCoupon({
        id,
        amount: type === 'full' ? amount : amount / 2,
      });

      setAmount(type === 'full' ? 0 : amount / 2);

      setIsLoading(false);
    } else if (error) {
      setCouponError(error);
      setIsLoading(false);
    }
  };

  const validateFields = () => {
    if (apiSuccess) {
      setApiSuccess(false);
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    const errors = Object.create({});
    Object.keys(opportunityFormErrors).map((name) => {
      if (name === 'pay' && opportunity[name] < 0) {
        errors[
          name
        ] = `${FIELD_LABELS[name]} can either be blank (non-paying) or greater than 0`;
      } else if (name === 'fee' && opportunity[name] < 0) {
        errors[
          name
        ] = `${FIELD_LABELS[name]} can either be blank (no-fee) or greater than 0`;
      } else if (
        name === 'externalUrl' &&
        !opportunity[name] &&
        opportunity['applyThrough'] === 'external'
      ) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (name === 'category' && !opportunity[name].length) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (
        name === 'isFeatured' &&
        OPPORTUNITY_RULES[name]?.required &&
        opportunity[name] === null
      ) {
        errors[name] = 'Please choose if you would like your post featured';
      } else if (
        OPPORTUNITY_RULES[name]?.required &&
        !opportunity[name] &&
        name !== 'isFeatured'
      ) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (opportunity[name] && name !== 'isFeatured') {
        if (
          OPPORTUNITY_RULES[name]?.minLength &&
          opportunity[name].toString().length <
            OPPORTUNITY_RULES[name]?.minLength
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should contain at least ${OPPORTUNITY_RULES[name]?.minLength} characters`;
        } else if (
          OPPORTUNITY_RULES[name]?.maxLength &&
          opportunity[name].toString().length >
            OPPORTUNITY_RULES[name]?.maxLength
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} can not be longer than ${OPPORTUNITY_RULES[name]?.maxLength} characters`;
        } else if (
          OPPORTUNITY_RULES[name]?.min &&
          opportunity[name] < OPPORTUNITY_RULES[name]?.min
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should be greater than ${OPPORTUNITY_RULES[name]?.min}`;
        } else if (
          OPPORTUNITY_RULES[name]?.max &&
          opportunity[name] < OPPORTUNITY_RULES[name]?.max
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should be lesser than ${OPPORTUNITY_RULES[name]?.max}`;
        } else if (
          name === 'referenceLinks' &&
          OPPORTUNITY_RULES[name]?.pattern
        ) {
          errors[name] = [];
          for (let i = 0; i < opportunity[name].length; i++) {
            if (
              opportunity[name][i] &&
              !OPPORTUNITY_RULES[name]?.pattern.test(opportunity[name][i])
            ) {
              errors[name][i] = `${FIELD_LABELS[name]} is invalid`;
            }
          }
          if (!errors[name].length) {
            delete errors[name];
          }
        } else if (
          OPPORTUNITY_RULES[name]?.pattern &&
          !OPPORTUNITY_RULES[name]?.pattern.test(opportunity[name])
        ) {
          errors[name] = `${FIELD_LABELS[name]} is invalid`;
        }
      }
      return name;
    });
    if (Object.keys(errors).length) {
      setOpportunityFormErrors({
        ...opportunityFormErrors,
        ...errors,
      });
      setApiError('There are some errors above, please fix those to proceed.');
      return false;
    }
    return true;
  };

  // Todo Validate file specs

  const callAnalytics = () => {
    window.analytics.track('Posted Opportunity', {
      careerTitle: opportunity.title,
      category: opportunity.category,
      companyName: opportunity.companyName,
      cost: opportunity.fee,
      pay: opportunity.pay,
      compensation: opportunity.pay,
      rate: opportunity.rate,
      duration: opportunity.expectedNeed,
      workLocation: opportunity.location,
      company: opportunity.companyName,
      city: opportunity.city,
      state: opportunity.state,
      jobDescription: opportunity.opportunityDescription,
      companyDescription: opportunity.hiringDescription,
      featureJob: opportunity.isFeatured,
      industry: opportunity.industry,
    });
  };

  const createJob = async (opportunityFormData: any) => {
    const response = await createJobService(opportunityFormData);

    if (!response.ok) {
      const error = await response.clone().text();
      setApiError(error || serverError);
    } else {
      const { id } = await response.json();
      callAnalytics();
      setJobId(id);
      setApiSuccess(true);
      setOpportunity(DEFAULT_OPPORTUNITY_VALUES);
    }

    setIsLoading(false);
  };

  const updateJob = async (opportunityFormData: any) => {
    const { id, error } = await updateJobService(postId, opportunityFormData);

    if (error) {
      setApiError(error || serverError);
    } else {
      setJobId(id);
      setApiSuccess(true);
    }

    setIsLoading(false);
  };

  /**
   * Create a opportunity
   */
  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    if (amount && !selectedCard) {
      setApiError('Please select/add a card to proceed with the payment');
      return;
    }

    setApiError('');
    setApiSuccess(false);
    setJobId('');
    setIsLoading(true);

    let uploadResponse = '';
    if (logo) {
      uploadResponse = await uploadLogo();
      if (!uploadResponse) {
        return;
      }
    }

    const opportunityFormData: any = {};
    Object.keys(opportunity).map((key: string) => {
      opportunityFormData[camelToSnake(key)] = opportunity[key];
      return key;
    });
    opportunityFormData['company_logo'] =
      uploadResponse || (job && job.company_logo ? job.company_logo : '');
    if (!opportunityFormData.pay) {
      opportunityFormData.pay = null;
    }
    if (!opportunityFormData.fee) {
      opportunityFormData.fee = null;
    }
    opportunityFormData.reference_links = opportunity['referenceLinks'].filter(
      (val) => {
        return !!val;
      }
    );
    opportunityFormData.category = opportunity.category
      .map((item) => item.name)
      .join(',');
    if (
      ((!postId || relist) &&
        (opportunityFormData.is_featured || opportunityFormData.fee)) ||
      (postId && !relist && opportunityFormData.fee && !job.fee)
    ) {
      if (amount) {
        opportunityFormData.cardId = selectedCard;
      }
      if (coupon) {
        opportunityFormData.coupon_id = coupon.id;
      }
    }
    opportunityFormData.type = 'opportunity';

    if (!postId || relist) {
      createJob(opportunityFormData);
    } else {
      delete opportunityFormData['is_featured'];
      updateJob(opportunityFormData);
    }
  };

  const renderTooltipCom = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      What will you pay someone? Can be &quot;$0&quot;
    </Tooltip>
  );

  const renderTooltipCost = (props: any) => (
    <Tooltip id="button-tooltipCost" {...props}>
      What will you charge someone for your service?
    </Tooltip>
  );

  const renderTooltipRate = (props: any) => (
    <Tooltip id="button-tooltipRate" {...props}>
      {OPPORTUNITY_CONFIG?.rate?.info}
    </Tooltip>
  );

  const renderTooltipLocation = (props: any) => (
    <Tooltip id="button-tooltipLocation" {...props}>
      {OPPORTUNITY_CONFIG?.location?.info}
    </Tooltip>
  );

  const renderTooltipApplyThrough = (props: any) => (
    <Tooltip id="button-tooltipApply" {...props}>
      {OPPORTUNITY_CONFIG?.applyThrough?.info}
    </Tooltip>
  );

  return (
    <Row className={styles.opportunityType}>
      {(!OPPORTUNITY_CONFIG?.title || !OPPORTUNITY_CONFIG?.title?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.title?.label || 'Opportunity Post Title'}{' '}
            <span>(max 100 characters)</span>
            <span className={styles.requiredField}> *</span>
            <div className={`${styles.requiredFieldsNote} text-right`}>
              <span>* </span>= Required Fields
            </div>
          </div>
          <Input
            type="text"
            onChange={handleChange}
            value={opportunity.title}
            name="title"
          />
          {opportunityFormErrors.title && (
            <div className={styles.error}>{opportunityFormErrors.title}</div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.category ||
        !OPPORTUNITY_CONFIG?.category?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.category?.label || 'Category'}{' '}
            <a
              href={
                COPY_OVERRIDES?.profile?.submit_category_link ||
                'https://musiccareermap.com/category-submission'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    COPY_OVERRIDES?.profile?.submit_category_text ||
                    'If you don&apos;t see your category listed, click here to submit it.',
                }}
              ></span>
            </a>
            <span className={styles.requiredField}> *</span>
          </div>
          <Multiselect
            options={categories || []}
            isObject={true}
            displayValue="name"
            selectedValues={opportunity.category}
            showCheckbox
            singleSelect={true}
            onSelect={handleCategorySelect}
            onRemove={handleCategorySelect}
          />
          {opportunityFormErrors.category && (
            <div className={styles.error}>{opportunityFormErrors.category}</div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.companyName ||
        !OPPORTUNITY_CONFIG?.companyName?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.companyName?.label ||
              'Hiring Company/Individual Name'}
            <span className={styles.requiredField}> *</span>
          </div>
          <Input
            type="text"
            onChange={handleChange}
            value={opportunity.companyName}
            name="companyName"
          />
          {opportunityFormErrors.companyName && (
            <div className={styles.error}>
              {opportunityFormErrors.companyName}
            </div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.fee || !OPPORTUNITY_CONFIG?.fee?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.fee?.label || 'Cost/Fee'}
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipCost}
            >
              <div className={styles.infoHover}>i</div>
            </OverlayTrigger>
          </div>
          <Input
            type="number"
            placeholder="$"
            onChange={handleChange}
            value={opportunity.fee}
            name="fee"
          />
          {opportunityFormErrors.fee && (
            <div className={styles.error}>{opportunityFormErrors.fee}</div>
          )}
        </Col>
      )}

      <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.additionalCosts}>
          <div className={styles.addTitle}>Additional Cost</div>
          <div className={styles.addDesc}>
            {OPPORTUNITY_CONFIG?.additionalCostBody?.label ||
              `Opportunities where the end user will have to pay (i.e. studio time,
            mixing, etc) will have a $10.00 fee to post.`}
          </div>
        </div>
      </Col>

      {(!OPPORTUNITY_CONFIG?.pay || !OPPORTUNITY_CONFIG?.pay?.hidden) && (
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.pay?.label || 'Compensation'}
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipCom}
            >
              <div className={styles.infoHover}>i</div>
            </OverlayTrigger>
          </div>
          <Input
            type="number"
            placeholder="$"
            onChange={handleChange}
            value={opportunity.pay}
            name="pay"
          />
          {opportunityFormErrors.pay && (
            <div className={styles.error}>{opportunityFormErrors.pay}</div>
          )}
        </Col>
      )}

      {!!INDUSTRIES?.length && (
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.industry?.label || 'Industry'}
            <span className={styles.requiredField}> *</span>
          </div>
          <Select
            id="industry"
            selectItems={INDUSTRIES}
            onChange={handleChange}
            name="industry"
            value={opportunity.industry}
          />
          {opportunityFormErrors.industry && (
            <div className={styles.error}>{opportunityFormErrors.industry}</div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.rate || !OPPORTUNITY_CONFIG?.rate?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.rate?.label || 'Compensation Rate'}
            {OPPORTUNITY_CONFIG?.rate?.info && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipRate}
              >
                <div className={styles.infoHover}>i</div>
              </OverlayTrigger>
            )}
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="free1"
              name="rate"
              isChecked={opportunity.rate === 'free'}
              onChange={handleChange}
              label="Non-Paying"
              value="free"
            />
            <Checkbox
              id="perHour1"
              name="rate"
              isChecked={opportunity.rate === 'hour'}
              onChange={handleChange}
              label="Per Hour"
              value="hour"
            />
            <Checkbox
              id="flatFee1"
              name="rate"
              isChecked={opportunity.rate === 'flat'}
              onChange={handleChange}
              label="Flat Fee"
              value="flat"
            />
            <Checkbox
              id="salaryOpp"
              name="rate"
              isChecked={opportunity.rate === 'salary'}
              onChange={handleChange}
              label="Salary"
              value="salary"
            />
            <Checkbox
              id="tbd"
              name="rate"
              isChecked={opportunity.rate === 'TBD'}
              onChange={handleChange}
              label="TBD"
              value="TBD"
            />
          </div>
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.expectedNeed ||
        !OPPORTUNITY_CONFIG?.expectedNeed?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.expectedNeed?.label || 'Position'}
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="fullTime1"
              name="expectedNeed"
              isChecked={opportunity.expectedNeed === 'full time'}
              onChange={handleChange}
              label="Full Time"
              value="full time"
            />
            <Checkbox
              id="partTime1"
              name="expectedNeed"
              isChecked={opportunity.expectedNeed === 'part time'}
              onChange={handleChange}
              label="Part Time"
              value="part time"
            />
            <Checkbox
              id="oneTime1"
              name="expectedNeed"
              isChecked={opportunity.expectedNeed === 'one time'}
              onChange={handleChange}
              label="One Time"
              value="one time"
            />
            <Checkbox
              id="freelanceOpp"
              name="expectedNeed"
              isChecked={opportunity.expectedNeed === 'freelance'}
              onChange={handleChange}
              label="Freelance"
              value="freelance"
            />
          </div>
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.location ||
        !OPPORTUNITY_CONFIG?.location?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.location?.label || 'Location'}
            <span className={styles.requiredField}> *</span>
            {OPPORTUNITY_CONFIG?.location?.info && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipLocation}
              >
                <div className={styles.infoHover}>i</div>
              </OverlayTrigger>
            )}
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="onSite1"
              name="location"
              isChecked={opportunity.location === 'on site'}
              onChange={handleChange}
              label="On-Site"
              value="on site"
            />
            <Checkbox
              id="remote1"
              name="location"
              isChecked={opportunity.location === 'remote'}
              onChange={handleChange}
              label="Remote"
              value="remote"
            />
          </div>
          {opportunityFormErrors.location && (
            <div className={styles.error}>{opportunityFormErrors.location}</div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.logo || !OPPORTUNITY_CONFIG?.logo?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.logo?.label || 'Company Logo/Image'}
          </div>
          <FileUpload
            fileType={FILE_TYPE.IMAGE}
            fileSpecs={fileSpecs}
            onFileChange={setLogo}
            image={job?.company_logo}
            reset={apiSuccess && !postId}
          />
          {opportunityFormErrors.companyLogo && (
            <div className={styles.error}>
              {opportunityFormErrors.companyLogo}
            </div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.referenceLinks ||
        !OPPORTUNITY_CONFIG?.referenceLinks?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.referenceLinks?.label || 'Refrence Link(s)'}
          </div>
          <div className="mb-3">
            <Input
              type="text"
              placeholder="http://"
              onChange={(e) => handleReferenceLink(e, 0)}
              value={opportunity.referenceLinks[0] || ''}
              name="referenceLinks"
            />
            {opportunityFormErrors.referenceLinks[0] && (
              <div className={styles.error}>
                {opportunityFormErrors.referenceLinks[0]}
              </div>
            )}
          </div>
          <div className="mb-3">
            <Input
              type="text"
              placeholder="http://"
              onChange={(e) => handleReferenceLink(e, 1)}
              value={opportunity.referenceLinks[1] || ''}
              name="referenceLinks"
            />
            {opportunityFormErrors.referenceLinks[1] && (
              <div className={styles.error}>
                {opportunityFormErrors.referenceLinks[1]}
              </div>
            )}
          </div>
          <div className="mb-3">
            <Input
              type="text"
              placeholder="http://"
              onChange={(e) => handleReferenceLink(e, 2)}
              value={opportunity.referenceLinks[2] || ''}
              name="referenceLinks"
            />
            {opportunityFormErrors.referenceLinks[2] && (
              <div className={styles.error}>
                {opportunityFormErrors.referenceLinks[2]}
              </div>
            )}
          </div>
        </Col>
      )}

      <Col xl={12} lg={12} md={12} sm={12}>
        <Row>
          {(!OPPORTUNITY_CONFIG?.city || !OPPORTUNITY_CONFIG?.city?.hidden) && (
            <Col xl={6} lg={6} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {OPPORTUNITY_CONFIG?.city?.label || 'City'}
                <span className={styles.requiredField}> *</span>
              </div>
              <Input
                type="text"
                onChange={handleChange}
                value={opportunity.city}
                name="city"
              />
              {opportunityFormErrors.city && (
                <div className={styles.error}>{opportunityFormErrors.city}</div>
              )}
            </Col>
          )}

          {(!OPPORTUNITY_CONFIG?.state ||
            !OPPORTUNITY_CONFIG?.state?.hidden) && (
            <Col xl={3} lg={3} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {OPPORTUNITY_CONFIG?.state?.label || 'State'}
                <span className={styles.requiredField}> *</span>
              </div>
              <Select
                id="state"
                selectItems={STATES}
                onChange={handleChange}
                name="state"
                value={opportunity.state}
              />
              {opportunityFormErrors.state && (
                <div className={styles.error}>
                  {opportunityFormErrors.state}
                </div>
              )}
            </Col>
          )}

          {(!OPPORTUNITY_CONFIG?.zip || !OPPORTUNITY_CONFIG?.zip?.hidden) && (
            <Col xl={3} lg={3} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {OPPORTUNITY_CONFIG?.zip?.label || 'Zip'}
                <span className={styles.requiredField}> *</span>
              </div>
              <Input
                type="text"
                onChange={handleChange}
                value={opportunity.zip}
                name="zip"
              />
              {opportunityFormErrors.zip && (
                <div className={styles.error}>{opportunityFormErrors.zip}</div>
              )}
            </Col>
          )}
        </Row>
      </Col>

      {(!OPPORTUNITY_CONFIG?.opportunityDescription ||
        !OPPORTUNITY_CONFIG?.opportunityDescription?.hidden) && (
        <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.opportunityDescription?.label ||
              'Opportunity Description'}
            <span className={styles.requiredField}> *</span>
          </div>
          <TextArea
            onChange={handleChange}
            value={opportunity.opportunityDescription}
            name="opportunityDescription"
          />
          {opportunityFormErrors.opportunityDescription && (
            <div className={styles.error}>
              {opportunityFormErrors.opportunityDescription}
            </div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.hiringDescription ||
        !OPPORTUNITY_CONFIG?.hiringDescription?.hidden) && (
        <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {OPPORTUNITY_CONFIG?.hiringDescription?.label ||
              'Hiring Company/Individual Description'}
            <span className={styles.requiredField}> *</span>
          </div>
          <TextArea
            onChange={handleChange}
            value={opportunity.hiringDescription}
            name="hiringDescription"
          />
          {opportunityFormErrors.hiringDescription && (
            <div className={styles.error}>
              {opportunityFormErrors.hiringDescription}
            </div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.applyThrough ||
        !OPPORTUNITY_CONFIG?.applyThrough?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            Submit
            <span className={styles.requiredField}> *</span>
            {OPPORTUNITY_CONFIG?.applyThrough?.info && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipApplyThrough}
              >
                <div className={styles.infoHover}>i</div>
              </OverlayTrigger>
            )}
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="onThisSite1"
              name="applyThrough"
              isChecked={opportunity.applyThrough === 'site'}
              onChange={handleChange}
              label="On This Site"
              value="site"
            />
            <Checkbox
              id="externalURL1"
              name="applyThrough"
              isChecked={opportunity.applyThrough === 'external'}
              onChange={handleChange}
              label="External URL"
              value="external"
            />
          </div>
          {opportunityFormErrors.applyThrough && (
            <div className={styles.error}>
              {opportunityFormErrors.applyThrough}
            </div>
          )}
        </Col>
      )}

      {(!OPPORTUNITY_CONFIG?.applyThrough ||
        !OPPORTUNITY_CONFIG?.applyThrough?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>External URL</div>
          <Input
            type="text"
            placeholder="http://"
            onChange={handleChange}
            value={opportunity.externalUrl}
            name="externalUrl"
            disabled={opportunity.applyThrough !== 'external'}
          />
          {opportunityFormErrors.externalUrl && (
            <div className={styles.error}>
              {opportunityFormErrors.externalUrl}
            </div>
          )}
        </Col>
      )}

      <div className={styles.formDivider} />

      {(!postId || relist) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            Featured Listing?
            <span className={styles.requiredField}> *</span>
          </div>
          <div className={styles.featureDesc}>
            Featured listings gain visibility through top placement for category
            listings, in addition to receiving a dedicated email campaign to our
            applicant community and an account representative. Featured Listings
            remain for 30-days from posting and can be renewed in your dashboard
            with a single click.
          </div>
          <div className={styles.featureList}>
            <ul>
              <li>Account Representative</li>
              <li>Top Listing</li>
              <li>Email Campaign</li>
            </ul>
          </div>
          {opportunityFormErrors.isFeatured && (
            <div className={styles.error}>
              {opportunityFormErrors.isFeatured}
            </div>
          )}
        </Col>
      )}

      {(!postId || relist) && (
        <Col className={styles.limitedPriceData} xl={6} lg={6} md={12} sm={12}>
          <div className={styles.limitedPricing}>Limited Time Pricing</div>
          <div className={styles.limitedPrice}>
            <div className={styles.prices}>
              <s>${featuredAmount + 10}</s> &nbsp; <b>${featuredAmount}</b>
            </div>
          </div>
          <div className={styles.limitedButtons}>
            <Button
              onClick={() => handleFeatured(false)}
              type="gray my-2"
              label={`${
                opportunity.isFeatured === false ? '✔ ' : ''
              }Don't Feature My Post`}
            />
            <br />
            <Button
              onClick={() => handleFeatured(true)}
              type="gradient-blue"
              label={`${opportunity.isFeatured ? '✔ ' : ''}Feature My Post`}
            />
          </div>
        </Col>
      )}
      <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Questions?{' '}
          <a href={ROUTES.SUPPORT} target="_blank" rel="noopener noreferrer">
            {' '}
            Contact us
          </a>
        </div>
      </Col>
      {((!postId || relist) && (opportunity.isFeatured || opportunity.fee)) ||
      (postId && !relist && !job.fee && opportunity.fee) ? (
        <>
          <div className={styles.formDivider}></div>
          <Col xl={12} lg={12} md={12} sm={12} className={styles.posRel}>
            <Input
              type="text"
              placeholder="Coupon Code"
              onChange={handleCodeChange}
              value={code}
              name="code"
            />
            <Button
              onClick={verifyCouponCode}
              buttonType="button"
              label={!coupon ? 'Apply' : 'Remove'}
              type={`branding ${styles.applyCouponBtn}`}
              disabled={isLoading || !code}
              isLoading={isLoading}
            />
          </Col>
          {couponError && (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.jobSubmitError}>
                <Alert variant={'danger'} className={styles.apiError}>
                  {couponError}
                </Alert>
              </div>
            </Col>
          )}
          {amount > 0 ? (
            <>
              <Col xl={12} lg={12} md={12} sm={12} className="mt-3">
                <div className={styles.inputLabel}>
                  {coupon
                    ? `Applied coupon worth $${coupon.amount.toFixed(2)}. `
                    : ''}{' '}
                  Please provide payment details to pay ${amount.toFixed(2)}.
                </div>
              </Col>
              <PaymentForm setSelectedCard={setSelectedCard} />
            </>
          ) : (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {coupon ? `Applied coupon worth $${coupon.amount}. ` : ''}
              </div>
            </Col>
          )}
        </>
      ) : null}
      {apiError && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.jobSubmitError}>
            <Alert variant={'danger'} className={styles.apiError}>
              {apiError}
            </Alert>
          </div>
        </Col>
      )}
      <Col xl={12} lg={12} md={12} sm={12}>
        <Button
          onClick={handleSubmit}
          buttonType="button"
          type="large mt-5 py-3 w-100"
          label={postId && !relist ? 'Update Post' : 'Create Post'}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </Col>
      {apiSuccess && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.jobSubmitSuccess}>
            <Redirect to={`${ROUTES.CAREER}/${jobId}`} />
          </div>
        </Col>
      )}

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.subText}>
          Posts will last 30 days, you can refresh a post via your dashboard if
          you wish to continue the search.
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(Post);
