import React, { useState, Fragment } from 'react';
import moment from 'moment';
// import downloadjs from 'downloadjs';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import Badge from 'assets/svgs/certificateBadge.svg';
import Academic from 'assets/svgs/academic.svg';

import { downloadCredential } from 'services/UserService';

// Styles
import styles from './Credentials.module.scss';

interface Props {
  credentials: Array<any>;
}

const Credentials: React.FC<Props> = ({ credentials }: Props) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const downloadCred = async (id) => {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);
    const { error, data } = await downloadCredential(id);
    if (!error) {
      window.open(data.url, '__blank');
    }
    setIsDownloading(false);
  };

  return (
    <>
      {!!credentials?.length && (
        <div className={styles.resumeListing}>
          {credentials?.map((item, index) => (
            <Fragment key={index}>
              <div className={styles.sectionHeading}>Verified Certificates</div>
              <div key={index} className={styles.listing}>
                <div className={styles.badge}>
                  <SVG
                    description="Certificate Badge"
                    src={Badge}
                    title="Badge"
                  />
                </div>
                <div className={styles.resumeListRight}>
                  <div className={styles.resumeHeading}>
                    {item.credential_title}
                  </div>
                  <div className={styles.resumeInstitute}>
                    {item.verifying_party_company}
                  </div>
                  <div className={styles.resumeDate}>
                    Issued {moment(item.awarded_at).format('MMMM YYYY')} - No
                    Expiration Date
                  </div>
                  <div className={styles.resumeData}>
                    <Link to="#" onClick={() => downloadCred(item.id)}>
                      {!isDownloading ? 'See Certificate' : 'Downloading...'}
                    </Link>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      )}
      <div className={styles.resumeListing}>
        <div className={styles.sectionHeading}>Academic Certificates</div>
        <div className={`${styles.listing} ${styles.acaListing}`}>
          <div className={styles.badge}>
            <SVG
              description="Certificate Badge"
              src={Academic}
              title="Academic"
            />
          </div>
          <div className={styles.resumeListRight}>
            <div className={styles.resumeHeading}>
              Audio Engineering Certificate
            </div>
            <div className={styles.resumeInstitute}>musicindustry.academy</div>
            <div className={styles.resumeDate}>Issued March 2021</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Credentials;
