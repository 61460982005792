import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from './utils/History';

// Context
import GlobalState from './contexts/Global.state';
import GlobalContext from './contexts/Global.context';

import { App } from './App';

ReactDOM.render(
  <Router history={history}>
    <GlobalState>
      <GlobalContext.Consumer>{() => <App />}</GlobalContext.Consumer>
    </GlobalState>
  </Router>,
  document.getElementById('root')
);
