import React, { useState } from 'react';
import { Container, Row, InputGroup } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// Page Components
import Input from 'components/Form/Input/Input';
import Button from 'components/Button/Button';

// SCSS
import styles from './Filter.module.scss';

// SVGs
import Keyword from '../../../../../assets/svgs/Careers/filter-keyword.svg';
import Location from '../../../../../assets/svgs/Careers/filter-location.svg';

interface Props {
  keyword?: string;
  zip?: string;
  setKeyword: (searchText: string, zip: string) => void;
}

const Filter: React.FC<Props> = ({ keyword, zip, setKeyword }: Props) => {
  const [searchText, setSearchText] = useState<string>(keyword || '');
  const [zipCode, setZipCode] = useState<string>(zip || '');

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setZipCode(value);
  };

  const triggerSearch = () => {
    setKeyword(searchText, zipCode);
  };

  // trigger search on press of enter/return
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13 || event.charCode === 13) {
      setKeyword(searchText, zipCode);
    }
  };

  return (
    <div className={styles.filterForm}>
      <Container>
        <Row>
          <InputGroup className={styles.filterGroup}>
            <InputGroup.Prepend
              className={`${styles.keywordPrepend} ${styles.filterPrepend}`}
            >
              <InputGroup.Text>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="keyword-filter"
                  description="Keyword Filter Icon"
                  loader={<span>Loading...</span>}
                  src={Keyword}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Input
              class={`${styles.keywordFilter} ${styles.inputFilter}`}
              placeholder="Keyword Search"
              value={searchText}
              onChange={handleSearchTextChange}
              onKeyPress={handleKeyPress}
            />

            <div className={styles.divider} />

            <InputGroup.Prepend
              className={`${styles.locationPrepend} ${styles.filterPrepend}`}
            >
              <InputGroup.Text>
                <SVG
                  baseURL="/"
                  cacheRequests={true}
                  className="location-filter"
                  description="Location Filter Icon"
                  loader={<span>Loading...</span>}
                  src={Location}
                />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Input
              class={`${styles.locationFilter} ${styles.inputFilter}`}
              placeholder="Set Zipcode"
              value={zipCode}
              onChange={handleZipChange}
              onKeyPress={handleKeyPress}
            />
            <Button
              type={`branding ${styles.submitFilter}`}
              label="Search"
              onClick={triggerSearch}
            />
          </InputGroup>
        </Row>
      </Container>
    </div>
  );
};

export default React.memo(Filter);
