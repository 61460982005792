import { SECRET_API_KEY } from 'constants/Constants';

export const API_METHODS = {
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  PUT: 'PUT',
};

export const DEFAULT_API_HEADER = {
  'Content-Type': 'application/json',
  // Todo: move secret key in .env
  'x-api-key': process.env.REACT_APP_SECRET_API_KEY || SECRET_API_KEY,
};
