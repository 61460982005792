import React from 'react';
import { FormControl } from 'react-bootstrap';

// SCSS
import styles from './Input.module.scss';

interface Input {
  placeholder?: string;
  className?: string;
  type?: string;
  name?: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  class?: string;
  disabled?: boolean;
}

const Input = (props: Input) => {
  return (
    <FormControl
      className={`${props.class} ${styles.mdrInput} ${props.className}`}
      placeholder={props.placeholder}
      aria-label={props.placeholder}
      type={props.type}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      disabled={props.disabled}
    />
  );
};

export default React.memo(Input);
