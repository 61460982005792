import React, { useEffect, useContext, useState } from 'react';

import { Row, Col, Button, Alert } from 'react-bootstrap';

import Loader from 'components/Loader/Loader';
import Card from './Card';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import { Token, StripeError } from '@stripe/stripe-js';

import GlobalContext from 'contexts/Global.context';

import { fetchCardsService, addCardService } from 'services/CardService';

interface Card {
  address_city: string | null;
  address_country: string | null;
  address_line1: string | null;
  address_line1_check: string | null;
  address_line2: string | null;
  address_state: string | null;
  address_zip: string | null;
  address_zip_check: string | null;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: string | null;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  id: string;
  last4: string;
  metadata: object;
  name: string;
  object: string;
  tokenization_method: string | null;
}

interface NewCardError {
  code: string;
  message: string;
  type: string;
}

interface Props {
  cardSelectedForPayment: (cardId: string) => void;
}

const STRIPE_CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#111b47',
      minHeight: '55px',
      border: '1px solid #000000',
      '::placeholder': {
        color: '#111b47',
      },
    },
  },
  hidePostalCode: true,
};

const ExistingCards: React.FunctionComponent<Props> = ({
  cardSelectedForPayment,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const globalContext = useContext(GlobalContext);
  const {
    userDetails: { paymentId },
    setAuth,
  } = globalContext;

  const [userCards, setUserCards] = useState<null | Array<Card>>(null);
  const [fetchingCards, setFetchingCards] = useState<boolean>(false);

  const [selectedCard, setSelectedCard] = useState<string>('');

  const [showNewCardOption, setShowNewCardOption] = useState<boolean>(false);

  const [newCardLoader, setNewCardLoader] = useState<boolean>(false);
  const [newCardError, setNewCardError] = useState<string>('');

  const fetchUserCards = async () => {
    setFetchingCards(true);
    const { sources } = await fetchCardsService();
    if (sources && sources.data) {
      setUserCards(sources.data);
      if (sources.data.length) {
        setSelectedCard(sources.data[0].id);
        cardSelectedForPayment(sources.data[0].id);
      } else {
        setSelectedCard('');
        cardSelectedForPayment('');
      }
    }
    setFetchingCards(false);
  };

  useEffect(() => {
    const fetchCards = async () => {
      setFetchingCards(true);
      const { sources } = await fetchCardsService();
      if (sources && sources.data && sources.data.length) {
        setUserCards(sources.data);
        setSelectedCard(sources.data[0].id);
      }
      setFetchingCards(false);
    };

    fetchCards();
  }, [paymentId]);

  const addNewCard = async () => {
    setNewCardError('');
    if (!stripe && !elements) {
      return;
    }

    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        setNewCardLoader(true);
        const {
          token,
          error,
        }: {
          token?: Token | undefined;
          error?: StripeError | undefined;
        } = await stripe.createToken(cardElement);
        if (token) {
          const { newToken, error: cardError } = await addCardService(token.id);
          if (cardError) {
            setNewCardError(cardError);
          }
          if (newToken) {
            setAuth(newToken);
          }
          if (!cardError) {
            fetchUserCards();
            setShowNewCardOption(false);
          }
        } else if (error) {
          setNewCardError(error.message);
        }
        setNewCardLoader(false);
      }
    }
  };

  return (
    <Row className="checkout-form">
      <Col xl={12} lg={12} md={12} sm={12}>
        <div
          className="user-cards"
          style={{
            position: 'relative',
          }}
        >
          {fetchingCards && <Loader />}
          {!fetchingCards &&
            userCards &&
            userCards.map((card) => (
              <Card
                key={card.id}
                card={card}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                cardSelectedForPayment={cardSelectedForPayment}
                fetchUserCards={fetchUserCards}
              />
            ))}
        </div>
        {showNewCardOption || !userCards || (userCards && !userCards.length) ? (
          <form className="new-card-form" style={{ margin: '20px 0' }}>
            <Row className="align-items-center ml-2">
              <Col xl={8} lg={8} md={12} sm={12}>
                <CardElement
                  options={STRIPE_CARD_ELEMENT_OPTIONS}
                  onChange={() => setNewCardError('')}
                />
              </Col>
              <Col xl={4} lg={4} md={12} sm={12}>
                {!newCardLoader ? (
                  <Button
                    className="bm-btn ml-auto sm d-inline-block"
                    variant="primary"
                    onClick={addNewCard}
                  >
                    Add Card
                  </Button>
                ) : (
                  <Loader />
                )}
              </Col>
              {newCardError && <Alert variant="danger">{newCardError}</Alert>}
            </Row>
          </form>
        ) : null}
        {(!showNewCardOption || (userCards && userCards.length === 0)) && (
          <Button
            className="bm-btn mt-3 mb-5"
            variant="primary"
            onClick={() => setShowNewCardOption(true)}
          >
            Add new card
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ExistingCards;
