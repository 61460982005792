import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

//Components
import Welcome from './components/Welcome/Welcome';
import CareerMap from './components/CareerMap/CareerMap';

// SCSS
import styles from './About.module.scss';

const About = () => {
  return (
    <div className={styles.aboutPage}>
      <Container className="p-0">
        <Row>
          <Col md={12}>
            <Welcome />
          </Col>
        </Row>
        {process.env.REACT_APP_SHORT_TITLE === 'MIC' && (
          <Row>
            <Col md={12}>
              <CareerMap />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default About;
