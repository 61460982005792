import React, { useState, useContext } from 'react';
import { Container, Row, Col, Modal, Alert } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// Page Components
import Button from '~/../components/Button/Button';
import Input from '~/../components/Form/Input/Input';
import Checkbox from '~/../components/Form/Checkbox/Checkbox';
import FileUpload from 'components/FileUpload/FileUpload';
import History from 'utils/History';
import TextArea from 'components/Form/TextArea/TextArea';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faMapMarkerAlt,
  faUser,
  faFile,
  faFileWord,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import { ROUTES, JOB_TYPE } from 'constants/Constants';

// Service
import { applyForJobService } from 'services/JobService';
import { getUploadURLService } from 'services/CommonService';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SVGs
import applicationSent from '../../../../../assets/svgs/Careers/applicationsent.svg';

// SCSS
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  jobId: string;
  title?: string;
  location?: string;
  label?: string;
  rate?: string;
  pay?: number;
  postedBy?: string;
  applied?: boolean;
  externalURL?: string;
  type: string;
  applyThrough: string;
  company: string;
  isArchived: boolean;
}

const Header: React.FC<Props> = ({
  jobId,
  title,
  location,
  label,
  rate,
  pay,
  postedBy,
  applied,
  externalURL,
  type,
  applyThrough,
  company,
  isArchived,
}: Props) => {
  const {
    isLoggedIn,
    userDetails: {
      id: loggedInUserId,
      first_name,
      last_name,
      user_name,
      photo,
      email,
      city,
      state,
      resume = '',
      slug,
    },
  } = useContext(GlobalContext);

  const [show, setShow] = useState(false);

  const [showApplicationReceived, setShowApplicationReceived] = useState<
    boolean
  >(false);

  // form related state
  const [phone, setPhone] = useState<number | string>('');
  const [includePhone, setIncludePhone] = useState<string>('');
  const [currentResumeURL, setCurrentResumeURL] = useState<string>(resume);
  const [newResume, setNewResume] = useState<File | null>(null);
  const [note, setNote] = useState<string>('');

  // API related state
  const [loading, setLoading] = useState<boolean>(false);
  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleClose = () => {
    setShow(false);
    setIncludePhone('');
    setPhone('');
    setError('');
    setNewResume(null);
  };

  const handleShow = () => {
    if (isArchived) {
      return;
    }
    if (isLoggedIn) {
      if (applied) {
        return;
      }
      setShow(true);
    } else {
      History.push('/login?destination=' + window.location.pathname);
    }
  };

  const handleCloseAppSubModal = () => {
    setShowApplicationReceived(false);
  };

  const handleIncludePhoneChange = (event: any) => {
    const { id } = event.target;

    if (id === 'yes') {
      setIncludePhone(id);
    } else {
      setIncludePhone(id);
    }
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (x) {
      setPhone(
        !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
      );
    }
  };

  const getFileExtension = (fileName: string) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  };

  const uploadNewResume = async () => {
    if (newResume) {
      const fileInfo = {
        name: newResume.name,
        type: newResume.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        const uploadResumeResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: newResume,
        });

        if (uploadResumeResponse) {
          return { uploadResumeResponse, url };
        } else if (error) {
          setError(`An error occurred while uploading resume: ${error}`);
          return { error };
        }
      }
    }
  };

  const callAnalytics = () => {
    window.analytics.track(
      type === JOB_TYPE.career ? 'Apply for Job' : 'Applied for opportunity',
      {
        title,
        location,
        category: label.split(',')[0],
        pay,
        type,
        appliedBy: email,
      }
    );
  };

  const applyForJob = async (resumeURL?: string) => {
    setLoading(true);
    const apiBody = {
      phone,
      resume: resumeURL ? resumeURL : currentResumeURL || '',
      job: jobId,
      note,
    };
    const { applied, error } = await applyForJobService(apiBody);
    if (applied) {
      callAnalytics();
      setIsApplied(true);
      setShowApplicationReceived(true);
      setShow(false);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  };

  const triggerApplyForJob = async () => {
    setError('');

    if (newResume) {
      setLoading(true);
      const { uploadResumeResponse, url, error }: any = await uploadNewResume();
      if (uploadResumeResponse.ok && url) {
        applyForJob(url);
      } else if (error) {
        setError(error);
      }
    } else {
      applyForJob();
    }
  };

  const editPost = () => {
    History.push(`/edit-post/${type}/${jobId}`);
  };

  const removeResumeAttachment = () => {
    setCurrentResumeURL('');
    setNewResume(null);
  };

  return (
    <Row className={styles.resultHeader}>
      <Container>
        <Row className={`m-0 ${styles.resultHeaderRow}`}>
          <Col className="ml-auto" xl={7} lg={7} md={12} sm={12}>
            <div className={styles.title}>{title}</div>
            <div className={styles.jobData}>
              <span className={styles.label}>
                <FontAwesomeIcon icon={faBuilding} /> {company}
              </span>
              <span className={styles.location}>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> {location}
              </span>
            </div>
          </Col>
          <Col
            className={styles.resultHeaderButton}
            xl={3}
            lg={3}
            md={12}
            sm={12}
          >
            <div className={styles.resultHeaderAmount}>
              {pay ? (rate === 'hour' ? `$${pay}/hour` : `$${pay}`) : 'TBD'}
              {/* {pay ? `$${pay}/hour` : 'TBD'} */}
            </div>
            {loggedInUserId && loggedInUserId === postedBy ? (
              <Button
                type="branding"
                size="lg"
                label="Edit Post"
                onClick={editPost}
              />
            ) : externalURL && applyThrough === 'external' ? (
              <Button
                type="branding"
                size="lg"
                label="Learn More"
                link={externalURL}
              />
            ) : (
              <Button
                type="branding"
                size="lg"
                label={
                  applied || isApplied
                    ? 'Applied'
                    : isArchived
                    ? 'No Longer Available'
                    : 'Apply Today'
                }
                disabled={
                  loggedInUserId === postedBy ||
                  applied ||
                  isApplied ||
                  isArchived
                }
                onClick={handleShow}
              />
            )}

            <Modal
              size="lg"
              show={show}
              onHide={loading ? () => {} : handleClose}
              className={styles.modalWrap}
              centered
            >
              <Modal.Header
                className={`${styles.modalContainer} ${styles.modalHeader}`}
                closeButton
              >
                <div className={styles.modalSubTitle}>Apply For</div>
                <div className={styles.modalTitle}>{title}</div>
              </Modal.Header>
              <Modal.Body>
                <Row className={styles.modalDataWrap}>
                  <Col className={styles.modalAvatar} xl lg md={12} sm={12}>
                    <img
                      src={photo || 'https://placehold.co/70x70'}
                      alt={user_name}
                    />
                  </Col>
                  <Col className={styles.modalData} xl lg md={12} sm={12}>
                    <div className={styles.modalDataName}>
                      {`${first_name} ${last_name}`}
                    </div>
                    <div className={styles.modalDataInfo}>
                      {`${city}, ${state}`}
                      {/* <span className={styles.modalDataPipe}>|</span>
                      561-535-6664 */}
                      {/* <span className={styles.modalDataPipe}>|</span>
                      {email} */}
                    </div>
                    <div className={styles.modalDataLinks}>
                      <Link to={`${ROUTES.PROFILE}/${slug}`}>
                        <FontAwesomeIcon icon={faUser} />
                        View Profile
                      </Link>
                      &nbsp;
                      {resume && (
                        <>
                          <FontAwesomeIcon icon={faFile} />
                          <a
                            href={resume}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Resume
                          </a>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row
                  className={`${styles.modalContainer} ${styles.modalInputWrap}`}
                >
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.modalCheckBoxes}>
                      <div className={styles.modalInputLabel}>
                        Include Phone Number
                      </div>
                      <div className={styles.modalCheck}>
                        <Checkbox
                          id="yes"
                          label="Yes"
                          isChecked={includePhone === 'yes'}
                          onChange={handleIncludePhoneChange}
                        />
                      </div>
                      <div className={styles.modalCheck}>
                        <Checkbox
                          id="no"
                          label="No"
                          isChecked={includePhone === 'no'}
                          onChange={handleIncludePhoneChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.modalInput}>
                      <Input
                        placeholder="(555) 555-5555"
                        value={phone}
                        disabled={includePhone === 'no'}
                        onChange={handlePhoneChange}
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.modalInputLabel}>
                      Application Message
                    </div>
                  </Col>
                  <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.modalInput}>
                      <TextArea
                        placeholder='Add any additional notes/reference links, only if necessary for the application. We highly recommend not adding general messages like "Hire/Accept Me"'
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row
                  className={`my-4 ${styles.modalContainer} ${styles.modalInputWrap}`}
                >
                  <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.modalInputLabel}>
                      {process.env.REACT_APP_SHORT_TITLE !== 'CCE'
                        ? 'Include A Different Resume Than Your Standard?'
                        : 'Include A Different Document/Certificate Other Than Your Resume?'}
                    </div>
                  </Col>
                  <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
                    <Row className="m-0">
                      <Col
                        className="p-0 text-center"
                        xl={5}
                        lg={5}
                        md={5}
                        sm={5}
                      >
                        <FileUpload
                          buttonStyles="gray w-100"
                          onFileChange={setNewResume}
                        />
                        <div className={styles.fileInfo}>
                          pdf and doc files only | 5mbs max
                        </div>
                      </Col>
                      {currentResumeURL || newResume ? (
                        <Col className="p-0" xl={7} lg={7} md={7} sm={7}>
                          <div className={styles.myResult}>
                            <div className={styles.resultIcon}>
                              <FontAwesomeIcon
                                icon={
                                  getFileExtension(
                                    newResume?.name ||
                                      currentResumeURL.substr(
                                        currentResumeURL.lastIndexOf('/') + 1
                                      )
                                  ) === 'pdf'
                                    ? faFilePdf
                                    : faFileWord
                                }
                              />
                            </div>
                            {process.env.REACT_APP_SHORT_TITLE !== 'CCE' ? (
                              <div className={styles.resultData}>
                                <div className={styles.resultName}>
                                  My Resume
                                </div>
                                <div className={styles.resultFileName}>
                                  {newResume
                                    ? `${newResume.name}`
                                    : resume
                                    ? resume.substr(resume.lastIndexOf('/') + 1)
                                    : ''}
                                </div>
                              </div>
                            ) : (
                              <div className={styles.resultData}>
                                <div className={styles.resultName}>
                                  {newResume
                                    ? `${newResume.name}`
                                    : resume
                                    ? resume.substr(resume.lastIndexOf('/') + 1)
                                    : ''}
                                </div>
                              </div>
                            )}
                            <div
                              className={styles.resultAddRemove}
                              onClick={removeResumeAttachment}
                            >
                              Remove
                            </div>
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                  <Col className="mt-3">
                    <Button
                      type="outline-gray w-100 p-3"
                      size="lg"
                      label={loading ? 'loading...' : 'Apply Now'}
                      disabled={isApplied || loading}
                      onClick={triggerApplyForJob}
                    />
                  </Col>
                </Row>
                {isApplied && (
                  <Alert variant="success">
                    Application sent successfully!
                  </Alert>
                )}
                {error && <Alert variant="danger">{error}</Alert>}
              </Modal.Body>
              <Modal.Footer className={styles.modalFooter}>
                Onsite applications are submitted to the posting party, while
                external sources are redirected to the offsite application link.
                The posting party is responsible for communicating with
                applicants.{' '}
                {COPY_OVERRIDES?.apply?.company || 'Music Career Map'} cannot
                assist with any communication.
              </Modal.Footer>
            </Modal>

            {/* Application submission Modal */}
            <Modal
              size="lg"
              show={showApplicationReceived}
              onHide={loading ? () => {} : handleCloseAppSubModal}
              className={styles.applicationSentModalWrap}
              centered
            >
              <Modal.Body className={styles.applicationModalBody}>
                <div className={styles.applicationReceived}>
                  <div className={styles.planeIconContainer}>
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.planeIcon}
                      description="Application Sent"
                      loader={<span>Loading...</span>}
                      src={applicationSent}
                    />
                  </div>
                  <div className={styles.bigText}>Thank you for applying.</div>
                </div>
                <Row>
                  <Col className="mt-3">
                    <Button
                      type="outline-gray w-100 p-3"
                      size="sm"
                      label="Close"
                      onClick={handleCloseAppSubModal}
                    />
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default React.memo(Header);
