import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import qs from 'qs';

// Page Components
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Filter from './components/Content/Filter/Filter';
import Listing from './components/Content/Listing/Listing';

// Interfaces
import { SearchType } from 'interfaces/Search.types';
import { Job } from 'interfaces/Job.types';

// Services
import { fetchJobsService } from 'services/JobService';

// Utils
import { addParamsToURL } from 'utils/UtilityFunctions';
import History from 'utils/History';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './Search.module.scss';
import {
  DEFAULT_PAGINATION_PARAMS,
  ITEMS_PER_PAGE,
  JOB_TYPE,
} from 'constants/Constants';

interface Query {
  type: string;
  expectedNeed?: Array<string>;
  pay: Array<Array<number>>;
  location?: Array<string>;
  rate?: Array<string>;
  fee?: Array<string>;
  industry?: Array<string>;
  forMeOnly?: boolean;
}

const getPayArray = (pay) => {
  return pay.split(',').map((item) => {
    if (item.indexOf('-') > -1) {
      return [parseInt(item.split('-')[0]), parseInt(item.split('-')[1])];
    }
    return item;
  });
};

const Search = (props: SearchType) => {
  const { search, pathname } = useLocation();
  const {
    keyword: _keyword,
    zip: _zip,
    position,
    location: _location,
    pay: _pay,
    rate: _rate,
    fee: _fee,
    mine: _mine,
    industry: _industry,
  } = qs.parse(search, { ignoreQueryPrefix: true });

  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [totalJobs, setTotalJobs] = useState<number | null>(null);
  const [skip, setSkip] = useState<number>(0);

  const [keyword, setKeyword] = useState<string>(_keyword || '');

  const [zip, setZip] = useState<string>(_zip || '');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [query, setQuery] = useState<Query>({
    type: props.type,
    expectedNeed: position ? position.split(',') : [],
    location: _location ? _location.split(',') : [],
    pay: _pay ? getPayArray(_pay) : [],
    rate: _rate ? _rate.split(',') : [],
    fee: _fee ? _fee.split(',') : [],
    forMeOnly: _mine || false,
    industry: _industry ? _industry.split(',') : [],
  });

  const setQueryString = useCallback(() => {
    setTimeout(() => {
      const queryObj: any = {};
      if (keyword) {
        queryObj.keyword = keyword;
      }
      if (zip) {
        queryObj.zip = zip;
      }
      for (let key in query) {
        if (
          query[key] &&
          (query[key].length || typeof query[key] === 'boolean')
        ) {
          if (key === 'forMeOnly') {
            queryObj['mine'] = query[key];
          } else if (key === 'expectedNeed') {
            queryObj['position'] = query[key];
          } else if (key === 'pay') {
            queryObj['pay'] = query[key].map((item) => {
              if (typeof item === 'string') {
                return item;
              }
              return item.join('-');
            });
          } else {
            queryObj[key] = query[key];
          }
        }
      }
      if (Object.keys(queryObj).length) {
        History.push({
          pathname,
          search: addParamsToURL(queryObj),
        });
      }
    }, 0);
  }, [keyword, zip, query, pathname]);

  const onSearchTrigger = (searchText: string, zip: string) => {
    setSkip(0);
    setKeyword(searchText);
    setZip(zip);
  };

  const onPaymentFilterChange = (value: Array<Array<number>>) => {
    if (value) {
      setSkip(0);
      setQuery({
        ...query,
        pay: value,
      });
    }
  };

  const onLocationFilterChange = (location: Array<string>) => {
    setSkip(0);
    setQuery({
      ...query,
      location: location,
    });
  };

  const onCostFilterChange = (fee: Array<string>) => {
    setSkip(0);
    setQuery({
      ...query,
      fee: fee,
    });
  };

  const onExpectedNeedChange = (need: Array<string>) => {
    setSkip(0);
    setQuery({
      ...query,
      expectedNeed: need,
    });
  };

  const onRateChange = (need: Array<string>) => {
    setSkip(0);
    setQuery({
      ...query,
      rate: need,
    });
  };

  const onIndustryChange = (industry: Array<string>) => {
    setSkip(0);
    setQuery({
      ...query,
      industry,
    });
  };

  const onCategoryChange = () => {
    setSkip(0);
    setQuery({
      ...query,
      forMeOnly: !query.forMeOnly,
    });
  };

  const fetchMoreJobs = (page: number) => {
    setSkip((page - 1) * ITEMS_PER_PAGE);
  };

  // Function to fetch jobs on mount as well as chnage on the filters
  const fetchJobs = useCallback(async () => {
    setLoading(true);
    setError('');
    setQueryString();
    if (!skip) {
      setJobs([]);
    }
    // changing properties to snake_case
    const queryObj = {
      type: query.type,
      expected_need: query.expectedNeed,
      location: query.location,
      pay: query.pay,
      rate: query.rate,
      fee: query.fee,
      industry: query.industry,
      for_me_only: query.forMeOnly,
      keyword,
      zip,
    };
    const { jobs: fetchedJobs, jobsCount, error } = await fetchJobsService(
      queryObj,
      {
        ...DEFAULT_PAGINATION_PARAMS,
        skip,
      }
    );

    if (fetchedJobs) {
      if (skip) {
        setJobs((jobs) => [...jobs, ...fetchedJobs]);
        setTotalJobs(jobsCount);
      } else {
        setJobs(fetchedJobs);
        setTotalJobs(jobsCount);
      }
    } else if (error) {
      setError(error);
    }

    setLoading(false);
  }, [query, skip, keyword, zip, setQueryString]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  return (
    <Container className={styles.searchPage} fluid>
      <Container className="p-0">
        <Header
          title={props.title}
          subtitle={`${totalJobs ? totalJobs : 0} ${
            props.type === JOB_TYPE.career
              ? COPY_OVERRIDES?.dashboard?.careers || 'careers'
              : COPY_OVERRIDES?.dashboard?.opportunities || 'opportunities'
          } are available now.`}
        />
        <Row>
          <Col xl lg md={12} sm={12} className={styles.pageSidebar}>
            <Sidebar
              type={props.type}
              title={props.subtitle}
              checkboxValues={query}
              onPaymentFilterChange={onPaymentFilterChange}
              onLocationFilterChange={onLocationFilterChange}
              onExpectedNeedChange={onExpectedNeedChange}
              onRateChange={onRateChange}
              onIndustryChange={onIndustryChange}
              onCostFilterChange={onCostFilterChange}
              onCategoryFilterChange={onCategoryChange}
            />
          </Col>
          <Col xl lg md={12} sm={12} className={styles.pageContent}>
            <Filter keyword={keyword} setKeyword={onSearchTrigger} />
            <Listing
              type={props.type}
              loading={loading}
              jobs={jobs}
              totalJobs={totalJobs}
              fetchMoreJobs={fetchMoreJobs}
              error={error}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Search);
