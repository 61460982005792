import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

// Components
import Hero from './components/Hero/Hero';
import Listing from './components/Listing/Listing';
import MeetOurPartners from './components/MeetOurPartners/MeetOurPartners';

// Constants
import { JOB_TYPE } from 'constants/Constants';

// Interfaces
import { Job } from 'interfaces/Job.types';

// Services
import { fetchJobsService } from 'services/JobService';

// SCSS
// import styles from './Home.module.scss';

const Home: React.FC = () => {
  const [careers, setCareers] = useState<Array<Job> | null>(null);
  const [careersCount, setCareersCount] = useState<number | null>(null);

  const [opportunities, setOpportunities] = useState<Array<Job> | null>(null);
  const [opportunityCount, setOpportunityCount] = useState<number | null>(null);

  const fetchCareers = async () => {
    const { jobs, jobsCount } = await fetchJobsService({
      type: JOB_TYPE.career,
    });
    if (jobs) {
      setCareers(jobs);
      setCareersCount(jobsCount);
    }
  };

  const fetchOpportunities = async () => {
    const { jobs, jobsCount } = await fetchJobsService({
      type: JOB_TYPE.opportunity,
    });
    if (jobs) {
      setOpportunities(jobs);
      setOpportunityCount(jobsCount);
    }
  };

  useEffect(() => {
    fetchCareers();
    fetchOpportunities();
  }, []);

  return (
    <Container fluid>
      <Container className="p-0">
        {/* Hero */}
        <Hero />

        {/* Job Board Listing */}
        <Listing
          careers={careers}
          careersCount={careersCount}
          opportunities={opportunities}
          opportunityCount={opportunityCount}
        />

        {/* Meet Our Partners */}
        <MeetOurPartners />
      </Container>
    </Container>
  );
};

export default React.memo(Home);
