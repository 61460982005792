import { API_ENDPOINTS } from 'constants/Constants';
import { API_METHODS, DEFAULT_API_HEADER } from 'config/ApiConfig';

/**
 * Authenticate user token service to check if the token is valid
 */
export const authenticateLoginService = () => {
  return fetch(API_ENDPOINTS.VALIDATE_TOKEN, {
    method: API_METHODS.GET,
    headers: {
      ...DEFAULT_API_HEADER,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

export const verifyCouponService = async (code: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.VERIFY_COUPON}/${code}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { id, type }: any = await response.json();
      return {
        id,
        type,
      };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchCategoriesService = async () => {
  try {
    const response = await fetch(API_ENDPOINTS.FETCH_CATEGORIES, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
      },
    });
    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const categories: any = await response.json();
      return categories;
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const getUploadURLService = async (fileInfo: any) => {
  const { name, type } = fileInfo;
  const fileExtension = name.substr(name.lastIndexOf('.'));
  const fileName = name.substr(0, name.lastIndexOf('.')) + fileExtension;
  const apiURL = `${API_ENDPOINTS.GET_UPLOAD_URL}?fileName=${fileName}&fileType=${type}`;

  try {
    const response = await fetch(apiURL, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      return {
        error: await response.clone().text(),
      };
    } else {
      const { signedRequest, url }: any = await response.json();
      return {
        signedRequest,
        url,
      };
    }
  } catch (err) {
    return { error: err.message };
  }
};
