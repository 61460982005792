import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

// Page Components
import Button from 'components/Button/Button';
import Result from '../../../Result/Result';

// Interfaces
import { Job } from 'interfaces/Job.types';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './More.module.scss';
import { ROUTES, JOB_TYPE } from 'constants/Constants';

interface Props {
  jobCategory: string;
  jobs: Array<Job> | null;
  jobType: string;
}

const More: React.FC<Props> = ({ jobCategory, jobs, jobType }: Props) => {
  return (
    <div className={styles.moreResults}>
      <div className={styles.moreLabel}>
        More{' '}
        {jobType === JOB_TYPE.career
          ? COPY_OVERRIDES?.header?.careers || 'Careers'
          : COPY_OVERRIDES?.header?.opportunities || 'Opportunities'}
      </div>

      <div className={styles.resultsWrapper}>
        {jobs &&
          jobs
            .slice(0, 3)
            .map((job) => (
              <Result
                key={job.id}
                id={job.id}
                image={job.company_logo || 'https://placehold.co/70x70'}
                title={job.title}
                category={job.category}
                location={job.location}
                type={job.expected_need}
                date={job.created_at}
                city={job.city}
                state={job.state}
                jobType={job.type}
              />
            ))}
      </div>

      {jobs && jobs.length && jobs.length > 3 && (
        <Row className="mt-5">
          <Col
            className={`text-center ${styles.listingViewAll}`}
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <Link
              to={
                jobType === JOB_TYPE.career
                  ? `${ROUTES.CAREERS}?keyword=${jobCategory}`
                  : `${ROUTES.OPPORTUNITIES}?keyword=${jobCategory}`
              }
            >
              <Button type="large" label="View All" />
            </Link>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(More);
