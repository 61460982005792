import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, Nav, Tab, Alert } from 'react-bootstrap';
import qs from 'qs';

// Types
import CareerType from './components/Career/Career';
import OpportunityType from './components/Opportunity/Opportunity';
import { Job } from 'interfaces/Job.types';
import { Category } from 'interfaces/Category.types';

// Components
import Loader from 'components/Loader/Loader';

// Services
import { fetchJobDetailsService } from 'services/JobService';
import { fetchCategoriesService } from 'services/CommonService';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// SCSS
import styles from './Post.module.scss';

const Post: React.FC = () => {
  const { id, type } = useParams();
  const { id: idQuery, type: typeQuery } = qs.parse(useLocation().search, {
    ignoreQueryPrefix: true,
  });
  const [job, setJob] = useState<Job | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [categories, setCategories] = useState<Array<Category | any>>([]);

  const fetchCategories = useCallback(async () => {
    const categories: Array<Category> = await fetchCategoriesService();

    if (categories && categories.length) {
      setCategories(categories);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
    (async function () {
      if (id || idQuery) {
        setIsLoading(true);
        const { jobDetails, error } = await fetchJobDetailsService(
          id || idQuery
        );
        if (jobDetails) {
          setJob({
            ...jobDetails,
            category: [{ name: jobDetails.category.split(',')[0] }],
          });
        }
        if (error) {
          setError(error);
        }
        setIsLoading(false);
      }
    })();
  }, [id, idQuery, fetchCategories]);

  return (
    <Container className={styles.postJob} fluid>
      <Container className="p-0">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.postMainTitle}>
              {!id && !idQuery && process.env.REACT_APP_SHORT_TITLE !== 'CCE'
                ? 'Interested in finding someone?'
                : ''}
              {idQuery ? `Relist ${typeQuery}` : ''}
              {id ? `Edit ${type}` : ''}
            </div>
            {!id && !idQuery && process.env.REACT_APP_SHORT_TITLE !== 'CCE' && (
              <div className={styles.postSubTitle}>
                Let’s create your post to find the best talent.
              </div>
            )}
          </Col>
          <Col>
            <Tab.Container
              id="job-board-post-form"
              defaultActiveKey={type || typeQuery}
            >
              <Row>
                {/* Type Switcher */}
                {!id && !idQuery && (
                  <Col
                    className={styles.typeSwitcher}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                  >
                    <div className={styles.postNavLabel}>
                      What type of position is it?
                    </div>
                    <Nav
                      variant="tabs"
                      className={`flex-column ${styles.postTabs}`}
                    >
                      <Row>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <Nav.Item>
                            <Nav.Link
                              className={styles.postTab}
                              eventKey="career"
                            >
                              <div className={styles.postTabTitle}>
                                {COPY_OVERRIDES?.post?.career || 'Career'}
                              </div>
                              <div className={styles.postTabDesc}>
                                {COPY_OVERRIDES?.post?.career_body ||
                                  'Create a listing for any type of full time or contract work, internship, etc. All Career Posts are free to create and offer premium services to help you find applicants.'}
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <Nav.Item>
                            <Nav.Link
                              className={styles.postTab}
                              eventKey="opportunity"
                            >
                              <div className={styles.postTabTitle}>
                                {COPY_OVERRIDES?.post?.opportunity ||
                                  'Opportunity'}
                              </div>
                              <div className={styles.postTabDesc}>
                                {COPY_OVERRIDES?.post?.opportunity_body ||
                                  'Create a listing for any peer to peer collaborations (seeking musicians, etc.), service offered (consulting, mentoring, lessons, etc.) or any other non-career opportunities. All non-premium (fee-based) opportunities are free to post, and premium opportunities can be posted for a flat fee every 30-days. Premium posters handle their own transactions and keep 100% of their fees.'}
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Col>
                      </Row>
                    </Nav>
                  </Col>
                )}

                {/* Type Form Post */}
                {(!id && !idQuery) || job ? (
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <Tab.Content>
                      {/* Career */}
                      <Tab.Pane eventKey="career">
                        <CareerType
                          id={id || idQuery}
                          job={job}
                          categories={categories}
                          relist={!!idQuery}
                        />
                      </Tab.Pane>

                      {/* Opportunity */}
                      <Tab.Pane eventKey="opportunity">
                        <OpportunityType
                          id={id || idQuery}
                          job={job}
                          categories={categories}
                          relist={!!idQuery}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                ) : (
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <Tab.Content>
                      {/* Career */}
                      <Tab.Pane eventKey={type || typeQuery}>
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <div className={styles.jobSubmitError}>
                            <Alert
                              variant={'danger'}
                              className={styles.apiError}
                            >
                              {error}
                            </Alert>
                          </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                )}
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Post);
