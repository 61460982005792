import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import SVG from 'react-inlinesvg';

import { Container, Row, Col } from 'react-bootstrap';

import Credentials from 'components/Credentials/Credentials';

// SVGs
import PDF from 'assets/svgs/pdf.svg';
// import Certificate from 'assets/svgs/mixing.svg';

// Context
import GlobalContext from 'contexts/Global.context';

// Interfaces
import { ApplicantProfile } from 'interfaces/User.types';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faArrowCircleUp,
} from '@fortawesome/free-solid-svg-icons';

// Constants
import { ROUTES } from 'constants/Constants';

// Styles
import styles from './Content.module.scss';

interface Props {
  profile: ApplicantProfile;
}

const Content: React.FC<Props> = ({ profile }: Props) => {
  const {
    userDetails: { id: loggedInUserId },
  } = useContext(GlobalContext);

  const renderSkills = (key: string) => {
    // eslint-disable-next-line react/prop-types
    return (
      <div className={styles.skills}>
        {profile[key]
          ? // eslint-disable-next-line react/prop-types
            profile[key].split(',').map((skill) => (
              <div key={skill} className={styles.skill}>
                {skill.trim()}
              </div>
            ))
          : null}
      </div>
    );
  };

  const getClickableLink = (link) => {
    return link.startsWith('http://') || link.startsWith('https://')
      ? link
      : `//${link}`;
  };

  const renderAdditionalLinks = () => {
    // eslint-disable-next-line react/prop-types
    const { additional_links: additionalLinks } = profile;
    if (additionalLinks && additionalLinks.length) {
      return (
        <div className={styles.box}>
          <div className={styles.label}>Additional Links</div>
          <div className={styles.boxContent}>
            <ul>
              {additionalLinks.map((additionalLink) => {
                if (additionalLink.title && additionalLink.link) {
                  return (
                    <li key={`${additionalLink.title}-${additionalLink.link}`}>
                      <a
                        href={getClickableLink(additionalLink.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {additionalLink.title}
                      </a>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Container className={styles.profileContentContainer}>
        <Row className="justify-content-md-center">
          <Col className={styles.p0}>
            <div className={styles.profileContentWrapper}>
              <div className={styles.leftContent}>
                <div className={styles.skillsBox}>
                  <div className={styles.label}>Seeking</div>
                  <div className={styles.boxContent}>
                    {renderSkills('career_categories')}
                  </div>
                </div>
                <div className={styles.skillsBox}>
                  <div className={styles.label}>Offering</div>
                  <div className={styles.boxContent}>
                    {renderSkills('opportunity_categories')}
                  </div>
                </div>
                <div className={styles.box}>
                  <div className={styles.label}>Joined</div>
                  <div className={styles.boxContent}>
                    <div className={styles.duration}>
                      {moment(profile.created_at).fromNow()}
                    </div>
                  </div>
                </div>
                {profile.id !== loggedInUserId && (
                  <div className={styles.box}>
                    <div className={styles.label}>Contact</div>
                    <div className={styles.boxContent}>
                      <Link
                        to={{
                          pathname: `${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${profile.id}`,
                          state: { userName: profile.user_name },
                        }}
                      >
                        Message Me
                      </Link>
                    </div>
                  </div>
                )}
                <div className={styles.box}>
                  <div className={styles.label}>Websites</div>
                  <div className={styles.boxContent}>
                    <ul>
                      {profile.twitter && (
                        <li>
                          <a
                            href={getClickableLink(profile.twitter)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faTwitter}
                                className={styles.socialIcon}
                              />
                            </span>
                            Twitter
                          </a>
                        </li>
                      )}
                      {profile.facebook && (
                        <li>
                          <a
                            href={getClickableLink(profile.facebook)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faFacebook}
                                className={styles.socialIcon}
                              />
                            </span>
                            Facebook
                          </a>
                        </li>
                      )}
                      {profile.instagram && (
                        <li>
                          <a
                            href={getClickableLink(profile.instagram)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faInstagram}
                                className={styles.socialIcon}
                              />
                            </span>
                            Instagram
                          </a>
                        </li>
                      )}
                      {profile.youtube && (
                        <li>
                          <a
                            href={getClickableLink(profile.youtube)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faYoutube}
                                className={styles.socialIcon}
                              />
                            </span>
                            Youtube
                          </a>
                        </li>
                      )}
                      {profile.website && (
                        <li>
                          <a
                            href={getClickableLink(profile.website)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faArrowCircleUp}
                                className={styles.socialIcon}
                              />
                            </span>
                            Website
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                {renderAdditionalLinks()}
              </div>
              <div className={styles.rightContent}>
                {/* <div className={styles.subHeading}>About</div>
                <div className={styles.description}>{profile.about}</div> */}
                <div className={styles.subHeading}>Biography</div>
                <div className={styles.description}>
                  <pre>{profile.bio}</pre>
                </div>
                {profile.resume ? (
                  <>
                    <div className={styles.subHeading}>My Resume</div>
                    <div className={styles.downloadResume}>
                      <a
                        href={profile.resume}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <div className={styles.pdfIcon}>
                          <SVG baseURL="/" description="PDF" src={PDF} />
                        </div>
                        <div className={styles.fileType}>
                          <div className={styles.fileName}>My Resume</div>
                          <div className={styles.fileExt}>
                            {profile.resume.substring(
                              profile.resume.lastIndexOf('/') + 1
                            )}
                          </div>
                        </div>
                        <div className={styles.download}>
                          <FontAwesomeIcon icon={faArrowDown} />
                        </div>
                      </a>
                    </div>
                  </>
                ) : null}
                {!!profile.certificates?.length && (
                  <>
                    <div className={styles.subHeading}>
                      Licenses &amp; Certifications
                    </div>
                    <div>
                      <Row>
                        <Col>
                          <Credentials credentials={profile.certificates} />
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Content;
