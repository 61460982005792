import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

// SVGs
import BadgeGrey from '../../../../assets/svgs/badgeGrey.svg';

// Interfaces
import { ApplicantProfile } from 'interfaces/User.types';

// Components
import Button from 'components/Button/Button';
import Credentials from 'components/Credentials/Credentials';

// Styles
import styles from './Resume.module.scss';

interface Props {
  profile: ApplicantProfile;
}

const Resume: React.FC<Props> = ({ profile }: Props) => {
  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            {/* <Row>
              <Col>
                <div className={styles.sectionHeading}>
                  Current Licenses &amp; Certifications On Your Profile
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Credentials credentials={profile?.certificates} />

                {!profile?.certificates?.length && (
                  <div className={styles.noCertificate}>
                    <div className={styles.noCertBadge}>
                      <SVG
                        description="No Certificate"
                        src={BadgeGrey}
                        title="Badge"
                      />
                    </div>
                    <div className={styles.noResumeHeading}>
                      You have no current certifications or licenses added just
                      yet.
                    </div>
                    <div className={styles.noResumeDate}>
                      If you’d like for one to be added please use the button
                      below.
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authButton}>
                  <Button
                    label="Request A Credential To Be Added"
                    type="large w-100 branding"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Resume;
