import React from 'react';

import { Link } from 'react-router-dom';

import { Container, Row, Col, Nav } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

// Components
import Image from 'components/Image/Image';

// SCSS
import './Footer.scss';

const LOGO = `${process.env.REACT_APP_ASSETS_URL}/${
  process.env.REACT_APP_FOOTER_LOGO || 'footer_logo.svg'
}`;

const Footer: React.FC = () => {
  const SOCIAL_LINKS = [],
    FOOTER_LINKS = [];
  if (process.env.REACT_APP_TWITTER) {
    SOCIAL_LINKS.push({
      name: 'twitter',
      link: process.env.REACT_APP_TWITTER,
      icon: faTwitter,
    });
  }
  if (process.env.REACT_APP_FACEBOOK) {
    SOCIAL_LINKS.push({
      name: 'facebook',
      link: process.env.REACT_APP_FACEBOOK,
      icon: faFacebookF,
    });
  }
  if (process.env.REACT_APP_INSTAGRAM) {
    SOCIAL_LINKS.push({
      name: 'instagram',
      link: process.env.REACT_APP_INSTAGRAM,
      icon: faInstagram,
    });
  }

  for (let i = 1; i < 7; i++) {
    if (process.env[`REACT_APP_FOOTER_LINKS_${i}_LINK`]) {
      FOOTER_LINKS.push({
        title: process.env[`REACT_APP_FOOTER_LINKS_${i}_TITLE`],
        link: process.env[`REACT_APP_FOOTER_LINKS_${i}_LINK`],
      });
    }
  }

  return (
    <Container className="footerWrap" fluid>
      <Container className="footer p-0">
        <Row className="align-items-center">
          <Col xl={1} lg={1} md={12} sm={12}>
            <Image
              className="footerLogo"
              src={LOGO}
              title={process.env.REACT_APP_SHORT_TITLE}
            />
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            <Nav
              className="flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column"
              activeKey="/home"
            >
              {FOOTER_LINKS.map(({ link, title }) => (
                <Nav.Item key={title}>
                  <Nav.Link to={link} as={Link} eventKey={title}>
                    {title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col xl={2} lg={2} md={12} sm={12} className="p-0">
            <Nav className="p-0 social-menu flex-row justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center">
              {SOCIAL_LINKS.map((socialLink) => (
                <a
                  key={socialLink.name}
                  href={socialLink.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={socialLink.icon} />
                </a>
              ))}
            </Nav>
          </Col>
          <Col
            className="text-xl-right text-lg-right text-md-center text-sm-center text-center copyrightOuter"
            xl={3}
            lg={3}
            md={12}
            sm={12}
          >
            <div className="copyright">
              &copy; {process.env.REACT_APP_COPYRIGHT}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Footer);
