import React, { useState } from 'react';

import { Container, Row, Col, Button, Modal, Alert } from 'react-bootstrap';

import Loader from 'components/Loader/Loader';
import Radio from 'components/Form/Checkbox/Checkbox';

import { Card as CardInterface } from 'interfaces/Card.types';
import { deleteCardService } from 'services/CardService';

interface Props {
  card: CardInterface;
  selectedCard: string;
  setSelectedCard: (id: string) => void;
  cardSelectedForPayment: (id: string) => void;
  fetchUserCards: () => void;
}

const Card: React.FunctionComponent<Props> = ({
  card,
  selectedCard,
  setSelectedCard,
  cardSelectedForPayment,
  fetchUserCards,
}: Props) => {
  const [deleteCardLoader, setDeleteCardLoader] = useState<boolean>(false);

  const [showDeleteCardPrompt, setShowDeleteCardPrompt] = useState<boolean>(
    false
  );
  const [isCardDeleted, setIsCardDeleted] = useState<boolean>(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setSelectedCard(!checked ? '' : value);
    cardSelectedForPayment(!checked ? '' : value);
  };

  const deleteCard = async (cardId: string) => {
    setDeleteCardLoader(true);
    const { deleted } = await deleteCardService(cardId);
    if (deleted) {
      setIsCardDeleted(true);
    }
    setDeleteCardLoader(false);
  };

  const handleClose = () => {
    setIsCardDeleted(false);
    setShowDeleteCardPrompt(false);
    fetchUserCards();
  };

  return (
    <Container className="p-0">
      <Row key={card.id} className="m-0 align-items-center">
        <Col className="p-0" xl={6} lg={6} md={6} sm={6}>
          <Radio
            id={card.id}
            name={card.id}
            label={`${card.brand} XXXX-XXXX-XXXX-${card.last4}`}
            isChecked={card.id === selectedCard}
            value={card.id}
            onChange={handleRadioChange}
          />
        </Col>

        <Col className="p-0 text-right" xl={6} lg={6} md={6} sm={6}>
          <Button
            className="bm-btn ml-auto d-xl-block d-lg-block d-md-none sm d-sm-none d-none"
            variant="outline-primary"
            onClick={() => setShowDeleteCardPrompt(true)}
          >
            Delete
          </Button>
        </Col>
      </Row>
      <div className="delete-card-modal">
        <Modal
          size="sm"
          show={showDeleteCardPrompt}
          onHide={
            isCardDeleted ? handleClose : () => setShowDeleteCardPrompt(false)
          }
          // dialogClassName={styles.modal90}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {deleteCardLoader && <Loader />}
            {!isCardDeleted && !deleteCardLoader && (
              //<div className="delete-card">

              <Alert variant="warning">
                Are you sure you want to delete this credit card from your
                account?
              </Alert>
              //</Modal.Body></div>
            )}
            {isCardDeleted && !deleteCardLoader && (
              <Alert variant="success">
                Your payment method is deleted successfully.
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            {!isCardDeleted && (
              <Button variant="primary" onClick={() => deleteCard(card.id)}>
                {deleteCardLoader ? 'Deleting...' : 'Delete'}
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={
                isCardDeleted
                  ? handleClose
                  : () => setShowDeleteCardPrompt(false)
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default React.memo(Card);
