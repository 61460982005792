import { API_ENDPOINTS } from 'constants/Constants';
import { API_METHODS, DEFAULT_API_HEADER } from 'config/ApiConfig';

// Add credit card
export const addCardService = async (token: string) => {
  try {
    const response = await fetch(API_ENDPOINTS.CARDS, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ token }),
    });

    if (response.ok) {
      const { token } = await response.json();
      return { newToken: token };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

// Fetch all cards added by customer
export const fetchCardsService = async () => {
  try {
    const response = await fetch(API_ENDPOINTS.CARDS, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const { sources } = await response.json();
      return { sources };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const deleteCardService = async (cardId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.CARDS}/${cardId}`, {
      method: API_METHODS.DELETE,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const deleteResponse = await response.json();
      return deleteResponse;
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};
