import React, { useState } from 'react';

// Components
import { Container, Row, Col, Alert } from 'react-bootstrap';
import Input from 'components/Form/Input/Input';
import Button from 'components/Button/Button';

// Constants
import { APP_CONSTANTS } from 'constants/Constants';

// Services
import { forgotPasswordService } from 'services/UserService';

// Styles
import styles from './ForgotPassword.module.scss';

const {
  errorConstants: { EMAIL_NOT_ENTERED_ERROR, EMAIL_IS_NOT_VALID },
  regExValidators: { EMAIL_VALIDATOR_REGEX },
} = APP_CONSTANTS;

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  /**
   * Handler that gets called when text in input field changes
   * @param {Object} event The event object
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'email') {
      if (!value) {
        setError(EMAIL_NOT_ENTERED_ERROR);
      } else {
        if (!EMAIL_VALIDATOR_REGEX.test(value)) {
          setError(EMAIL_IS_NOT_VALID);
        } else {
          setError('');
        }
      }
    }

    setEmail(value);
  };

  /**
   * Function to enable/disable the submit button
   */
  const isSubmitDisabled = () => {
    return !(email && EMAIL_VALIDATOR_REGEX.test(email));
  };

  /**
   * Function which on click sends an email to the user to reset the password
   */
  const sendAnEmail = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    const response = await forgotPasswordService(email);

    if (!response.ok) {
      setApiError(await response.clone().text());
    } else {
      const apiResponse = await response.json();
      if (apiResponse.result === 'success') {
        setIsEmailSent(true);
      }
    }
    setLoading(false);
    setEmail('');
  };

  return (
    <Container className={`${styles.authPage}`} fluid>
      <Container className={styles.authPageWrap}>
        <Row className="justify-content-md-center">
          <Col className={`${styles.authForm}`} xl={6} lg={6} md={12} sm={12}>
            <form onSubmit={sendAnEmail}>
              <div className={styles.authFormWrap}>
                <div className={styles.authFormTitle}>Forgot Password</div>

                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Email</div>
                  <Input
                    name="email"
                    type="text"
                    value={email}
                    onChange={handleInputChange}
                  />
                  {error && <div className={styles.error}>{error}</div>}
                </div>

                <div className={styles.authButton}>
                  <Button
                    type="large w-100"
                    buttonType="submit"
                    label={loading ? 'loading...' : 'Send Email'}
                    disabled={loading || isSubmitDisabled()}
                  />
                  {apiError && <Alert variant="danger">{apiError}</Alert>}
                  {isEmailSent && (
                    <Alert variant="success">
                      An email has been sent to you, please click reset your
                      password link to change your password.
                    </Alert>
                  )}
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(ForgotPassword);
