import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { Row, Col, Alert } from 'react-bootstrap';
import moment from 'moment';

// Components
import Result from '../../../JobBoard/Result/Result';
import Loader from 'components/Loader/Loader';

// Constants
import { JOB_TYPE } from 'constants/Constants';

// Context
import GlobalContext from 'contexts/Global.context';

import COPY_OVERRIDES from 'config/CopyOverrides';

// Interfaces
import { Job } from 'interfaces/Job.types';

// Services
import {
  fetchJobsService,
  fetchArchivedJobsService,
  deleteJobService,
  relistAJobService,
} from 'services/JobService';

// Styles
import styles from './CareersPosted.module.scss';

interface Props {
  type: string;
}

const CareersPosted: React.FC<Props> = ({ type }: Props) => {
  const {
    userDetails: { id },
  } = useContext(GlobalContext);

  // active jobs state
  const [loading, setLoading] = useState<boolean>(false);
  const [careersPosted, setCareersPosted] = useState<Array<Job> | null>(null);
  const [error, setError] = useState<string>('');

  // archived jobs state
  const [archivedLoading, setArchivedLoading] = useState<boolean>(false);
  const [archivedJobs, setArchivedJobs] = useState<Array<Job> | null>(null);

  // delete a job posting state
  // const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const fetchCareersPosted = useCallback(async () => {
    setCareersPosted(null);
    setLoading(true);
    const { jobs, error } = await fetchJobsService(
      {
        user: id,
        type,
      },
      { limit: 50, skip: 0, orderBy: 'created_at' }
    );

    if (jobs) {
      setCareersPosted(jobs);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [id, type]);

  const fetchArchivedJobs = useCallback(async () => {
    setArchivedJobs(null);
    setArchivedLoading(true);
    const { jobs, error } = await fetchArchivedJobsService({
      user: id,
      type,
      is_deleted: true,
    });

    if (jobs) {
      setArchivedJobs(jobs);
    } else if (error) {
      setError(error);
    }
    setArchivedLoading(false);
  }, [id, type]);

  const relistAJobPosting = async (jobId: string) => {
    // setRelistLoading(true);

    const { isRelisted } = await relistAJobService(jobId);

    if (isRelisted) {
      setCareersPosted(
        careersPosted.map((career) =>
          career.id === jobId
            ? { ...career, renewed_at: moment.utc().format() }
            : { ...career }
        )
      );
    }

    // setRelistLoading(false);
  };

  const deleteAJobPosting = async (jobId: string) => {
    // setDeleteLoading(true);
    const { isDeleted } = await deleteJobService(jobId);

    if (isDeleted) {
      setCareersPosted(careersPosted.filter((career) => career.id !== jobId));
      fetchArchivedJobs();
    }
    // setDeleteLoading(false);
  };

  useEffect(() => {
    fetchCareersPosted();
    fetchArchivedJobs();
  }, [fetchCareersPosted, fetchArchivedJobs]);

  const renderArchivedJobs = () => {
    return (
      <div className={styles.archivedJobs}>
        <div className={styles.sectionHeading}>{`Archived ${
          type === JOB_TYPE.career
            ? COPY_OVERRIDES?.dashboard?.careers || 'Careers'
            : COPY_OVERRIDES?.dashboard?.opportunities || 'Opportunities'
        }`}</div>

        <Row className={styles.careersPosted}>
          <Col>
            {archivedLoading ? <Loader /> : null}
            {archivedJobs &&
              archivedJobs.length > 0 &&
              archivedJobs.map((career) => (
                <Result
                  key={career.id}
                  id={career.id}
                  image={career.company_logo || 'https://placehold.co/70x70'}
                  title={career.title}
                  category={career.category}
                  location={career.location}
                  type={career.expected_need}
                  date={career.deleted_at}
                  city={career.city}
                  state={career.state}
                  jobType={career.type}
                  isArchived
                />
              ))}
            {!archivedLoading && archivedJobs && archivedJobs.length === 0 ? (
              <Alert variant="primary">
                {`No archived ${
                  type === JOB_TYPE.career
                    ? COPY_OVERRIDES?.dashboard?.careers || 'careers'
                    : COPY_OVERRIDES?.dashboard?.opportunities ||
                      'opportunities'
                } found`}
              </Alert>
            ) : null}
            {!archivedLoading && !archivedJobs && error ? (
              <Alert variant="danger">{error}</Alert>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>{`${
                  type === JOB_TYPE.career
                    ? COPY_OVERRIDES?.dashboard?.careers || 'Careers'
                    : COPY_OVERRIDES?.dashboard?.opportunities ||
                      'Opportunities'
                } I’ve Posted`}</div>
              </Col>
            </Row>
            <Row className={styles.careersPosted}>
              <Col>
                {loading ? <Loader /> : null}
                {careersPosted &&
                  careersPosted.length > 0 &&
                  careersPosted.map((career) => (
                    <Result
                      key={career.id}
                      id={career.id}
                      image={
                        career.company_logo || 'https://placehold.co/70x70'
                      }
                      title={career.title}
                      category={career.category}
                      location={career.location}
                      type={career.expected_need}
                      date={
                        career.renewed_at
                          ? career.renewed_at
                          : career.created_at
                      }
                      city={career.city}
                      state={career.state}
                      jobType={career.type}
                      isPosted
                      deleteAJobPosting={deleteAJobPosting}
                      relistAJobPosting={relistAJobPosting}
                    />
                  ))}
                {!loading && careersPosted && careersPosted.length === 0 ? (
                  <Alert variant="primary">
                    {`No ${
                      type === JOB_TYPE.career
                        ? COPY_OVERRIDES?.dashboard?.careers || 'careers'
                        : COPY_OVERRIDES?.dashboard?.opportunities ||
                          'opportunities'
                    } found`}
                  </Alert>
                ) : null}
                {!loading && !careersPosted && error ? (
                  <Alert variant="danger">{error}</Alert>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        {renderArchivedJobs()}
      </div>
    </Fragment>
  );
};

export default CareersPosted;
