import React from 'react';
import { Form } from 'react-bootstrap';

// SCSS
import styles from './Checkbox.module.scss';

interface Checkbox {
  id?: string;
  name?: string;
  label?: string;
  isChecked?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = (props: Checkbox) => {
  return (
    <div className={styles.mdrCheck}>
      <Form.Check
        id={props.id}
        label={props.label}
        aria-label={props.label}
        className={styles.mdrCheckbox}
        name={props.name}
        checked={props.isChecked}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};

export default React.memo(Checkbox);
