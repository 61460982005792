import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// SCSS
import styles from './MeetOurPartners.module.scss';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

const MeetOurPartners: React.FC = () => {
  const partners = [];
  for (let i = 1; i <= 5; i++) {
    if (process.env[`REACT_APP_PARTNER${i}_LINK`]) {
      partners.push({
        link: process.env[`REACT_APP_PARTNER${i}_LINK`],
        logo: `${process.env.REACT_APP_ASSETS_URL}/partner_${i}.png`,
        name: process.env[`REACT_APP_PARTNER${i}_NAME`],
      });
    }
  }

  return (
    <Container className={`${styles.meetOurPartners}`}>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.meetOurPartnersTitle}>
            {COPY_OVERRIDES?.homepage?.meet_our_partners || 'Meet Our Partners'}
          </div>
        </Col>

        <Col xl={12} lg={12} md={12} sm={12}>
          <Row className={styles.partnerItems}>
            {partners.map((partner) => {
              return (
                <div key={partner.name} className={styles.partnerItem}>
                  <div className={styles.partnerImage}>
                    <a
                      href={partner.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={partner.logo} alt={partner.name} />
                    </a>
                  </div>
                </div>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(MeetOurPartners);
