import React from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

// Components
import Button from 'components/Button/Button';
import Input from 'components/Form/Input/Input';
import TextArea from 'components/Form/TextArea/TextArea';
import FileUpload from 'components/FileUpload/FileUpload';
import Select from 'components/Form/Select/Select';

import { Multiselect } from 'multiselect-react-dropdown';

// Interfaces
import { UserDetailsType } from 'interfaces/Register.types';

// Constants
import { FILE_TYPE, ROUTES } from 'constants/Constants';
import INDUSTRIES from 'config/Industries';
import STATES from 'constants/States';

// SCSS
import styles from './UserDetailsForm.module.scss';
import { Category } from 'interfaces/Category.types';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

interface Props {
  categories?: Array<Category>;
  userDetails: UserDetailsType;
  userDetailsErrors: any;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCategorySelect: (list: Array<any>, key: string) => void;
  handleWebsiteInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAdditionalLinksChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePhotoChange: (photo: File | null) => void;
  handleResumeChange: (resume: File | null) => void;
  isSubmitDisabled: boolean;
  handleSubmit: () => void;
  loading: boolean;
  apiError?: string;
}

const renderTooltipCost = (props: any) => (
  <Tooltip
    className={styles.tooltipContainer}
    id="button-tooltipCost"
    {...props}
  >
    Categories are how we sort {COPY_OVERRIDES?.profile?.careers || 'Careers'}{' '}
    and {COPY_OVERRIDES?.profile?.opportunities || 'Opportunities'}, and also
    how users display their skills on their profiles. Select the Categories you
    want to showcase for hiring/getting hired.
  </Tooltip>
);

const HTTP_PLACEHOLDER = 'http://';

const UserDetailsForm: React.FunctionComponent<Props> = ({
  categories,
  userDetails,
  userDetailsErrors,
  handleInputChange,
  handleCategorySelect,
  handleWebsiteInputChange,
  handleAdditionalLinksChange,
  handlePhotoChange,
  handleResumeChange,
  isSubmitDisabled,
  handleSubmit,
  loading,
  apiError,
}: Props) => {
  return (
    <Container className={`mb-5 ${styles.registerContinued}`} fluid>
      <Container className="p-0">
        <Row className="my-5">
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerTitle}>Sign Up</div>
            <div className={styles.registerSubTitle}>
              Last step before you complete your profile.
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerIntro}>
              Almost finished, just need some final information so we can create
              a proper profile.
            </div>
            <div className={`${styles.requiredFieldsNote} text-right`}>
              <span>* </span>= Required Fields
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Personal Information</div>
              <div className={styles.registerSubLabel}>
                Tell us about yourself.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    First Name
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="firstName"
                    type="text"
                    value={userDetails.firstName}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.firstName ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.firstName && (
                    <div className={styles.error}>
                      {userDetailsErrors.firstName}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Last Name
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="lastName"
                    type="text"
                    value={userDetails.lastName}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.lastName ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.lastName && (
                    <div className={styles.error}>
                      {userDetailsErrors.lastName}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Bio
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <TextArea
                    name="bio"
                    value={userDetails.bio}
                    onChange={handleInputChange}
                    className={userDetailsErrors.bio ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.bio && (
                    <div className={styles.error}>{userDetailsErrors.bio}</div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    {COPY_OVERRIDES?.profile?.career_interests ||
                      'Career categories I am interested in'}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipCost}
                    >
                      <div className={styles.infoHover}>i</div>
                    </OverlayTrigger>
                    <span className={styles.shortMess}>
                      <a
                        href={
                          COPY_OVERRIDES?.profile?.submit_category_link ||
                          'https://musiccareermap.com/category-submission'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              COPY_OVERRIDES?.profile?.submit_category_text ||
                              'If you don&apos;t see your category listed, click here to submit it.',
                          }}
                        ></span>
                      </a>
                    </span>
                  </div>
                  <Multiselect
                    options={categories || []}
                    isObject={true}
                    displayValue="name"
                    showCheckbox
                    onSelect={(categories) =>
                      handleCategorySelect(categories, 'careerCategories')
                    }
                    onRemove={(categories) =>
                      handleCategorySelect(categories, 'careerCategories')
                    }
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    {COPY_OVERRIDES?.profile?.opportunity_interests ||
                      'Opportunities categories I am interested in'}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipCost}
                    >
                      <div className={styles.infoHover}>i</div>
                    </OverlayTrigger>
                    <span className={styles.shortMess}>
                      <a
                        href={
                          COPY_OVERRIDES?.profile?.submit_category_link ||
                          'https://musiccareermap.com/category-submission'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              COPY_OVERRIDES?.profile?.submit_category_text ||
                              'If you don&apos;t see your category listed, click here to submit it.',
                          }}
                        ></span>
                      </a>
                    </span>
                  </div>
                  <Multiselect
                    options={categories || []}
                    isObject={true}
                    displayValue="name"
                    showCheckbox
                    onSelect={(categories) =>
                      handleCategorySelect(categories, 'opportunityCategories')
                    }
                    onRemove={(categories) =>
                      handleCategorySelect(categories, 'opportunityCategories')
                    }
                  />
                </div>
              </Col>
              {!!INDUSTRIES?.length && (
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Industry
                      {process.env.REACT_APP_SHORT_TITLE === 'CCE' && (
                        <span className={styles.requiredField}> *</span>
                      )}
                    </div>
                    <Select
                      id="industry"
                      selectItems={INDUSTRIES}
                      onChange={handleInputChange}
                      name="industry"
                      value={userDetails.industry}
                    />
                    {userDetailsErrors.industry &&
                      process.env.REACT_APP_SHORT_TITLE === 'CCE' && (
                        <div className={styles.error}>
                          {userDetailsErrors.industry}
                        </div>
                      )}
                  </div>
                </Col>
              )}
              {/* <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    About
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <TextArea
                    name="about"
                    value={userDetails.about}
                    onChange={handleInputChange}
                    className={userDetailsErrors.about ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.about && (
                    <div className={styles.error}>
                      {userDetailsErrors.about}
                    </div>
                  )}
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Location</div>
              <div className={styles.registerSubLabel}>
                To help find better matches.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    City
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="city"
                    type="text"
                    value={userDetails.city}
                    onChange={handleInputChange}
                    className={userDetailsErrors.city ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.city && (
                    <div className={styles.error}>{userDetailsErrors.city}</div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    State
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Select
                    id="state"
                    selectItems={STATES}
                    onChange={handleInputChange}
                    name="state"
                    value={userDetails.state}
                  />
                  {userDetailsErrors.state && (
                    <div className={styles.error}>
                      {userDetailsErrors.state}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Zip
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="zip"
                    type="text"
                    value={userDetails.zip || ''}
                    onChange={handleInputChange}
                    className={userDetailsErrors.zip ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.zip && (
                    <div className={styles.error}>{userDetailsErrors.zip}</div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Upload Your Photo</div>
                  <FileUpload
                    fileType={FILE_TYPE.IMAGE}
                    fileSpecs={['5mb max', '500px wide', '500px tall']}
                    onFileChange={handlePhotoChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Add Additional Links</div>
              <div className={styles.registerSubLabel}>
                Personal or Business.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Website</div>
                  <Input
                    name="website"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.website}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.website ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.website && (
                    <div className={styles.error}>
                      {userDetailsErrors.website}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Facebook</div>
                  <Input
                    name="facebook"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.facebook}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.facebook ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.facebook && (
                    <div className={styles.error}>
                      {userDetailsErrors.facebook}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Twitter</div>
                  <Input
                    name="twitter"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.twitter}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.twitter ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.twitter && (
                    <div className={styles.error}>
                      {userDetailsErrors.twitter}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>YouTube</div>
                  <Input
                    name="youtube"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.youtube}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.youtube ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.youtube && (
                    <div className={styles.error}>
                      {userDetailsErrors.youtube}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Instagram</div>
                  <Input
                    name="instagram"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.instagram}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.instagram ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.instagram && (
                    <div className={styles.error}>
                      {userDetailsErrors.instagram}
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Additional Links</div>
                  <Row>
                    <Col xl={4} lg={4} md={12} sm={12}>
                      <Input
                        name="additionalLink1Title"
                        type="text"
                        placeholder="Title"
                        value={userDetails.additionalLink1.title}
                        onChange={handleAdditionalLinksChange}
                        className={
                          userDetailsErrors.additionalLink1Title
                            ? styles.errorInput
                            : ''
                        }
                      />
                      {userDetailsErrors.additionalLink1Title && (
                        <div className={styles.error}>
                          {userDetailsErrors.additionalLink1Title}
                        </div>
                      )}
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12}>
                      <Input
                        name="additionalLink1"
                        type="text"
                        placeholder={HTTP_PLACEHOLDER}
                        value={userDetails.additionalLink1.link}
                        onChange={handleAdditionalLinksChange}
                        className={
                          userDetailsErrors.additionalLink1
                            ? styles.errorInput
                            : ''
                        }
                      />
                      {userDetailsErrors.additionalLink1 && (
                        <div className={styles.error}>
                          {userDetailsErrors.additionalLink1}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <div className={`${styles.authInput} mt-3`}>
                    <Row>
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink2Title"
                          type="text"
                          placeholder="Title"
                          value={userDetails.additionalLink2.title}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink2Title
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink2Title && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink2Title}
                          </div>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink2"
                          type="text"
                          placeholder={HTTP_PLACEHOLDER}
                          value={userDetails.additionalLink2.link}
                          onChange={handleAdditionalLinksChange}
                          className={
                            userDetailsErrors.additionalLink2
                              ? styles.errorInput
                              : ''
                          }
                        />
                        {userDetailsErrors.additionalLink2 && (
                          <div className={styles.error}>
                            {userDetailsErrors.additionalLink2}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Upload Your Resume</div>
              <div className={styles.registerSubLabel}>
                Be careful about sharing information like personal emails or
                phone numbers. Onsite messaging is available for all users.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row className={styles.authButton}>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Upload Your Resume</div>
                  <FileUpload
                    fileType={FILE_TYPE.DOCUMENT}
                    fileSpecs={['pdf and doc files only', '5mbs max']}
                    onFileChange={handleResumeChange}
                  />
                </div>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className={styles.signUpTerms}
              >
                By signing up you are agreeing to our{' '}
                <a
                  href={ROUTES.TERMS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service.
                </a>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Button
                  type="large w-100"
                  label={loading ? 'loading...' : 'Finish My Account'}
                  disabled={loading || isSubmitDisabled}
                  onClick={handleSubmit}
                ></Button>
                {apiError && <Alert variant="danger">{apiError}</Alert>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(UserDetailsForm);
