import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';

import { Link, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import socketIOClient from 'socket.io-client';
import { Row, Col, Alert } from 'react-bootstrap';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Components
import Button from '../../../../../components/Button/Button';
import TextArea from '../../../../../components/Form/TextArea/TextArea';
import Loader from 'components/Loader/Loader';

import History from 'utils/History';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import { ROUTES, BASE_URL } from 'constants/Constants';

// Interfaces
import { Message } from 'interfaces/Message.types';

// Services
import {
  getMessagesService,
  sendMessageService,
  markConversationReadService,
} from 'services/MessageService';
import { reportUserService } from 'services/UserService';

// Styles
import styles from './MessageDetail.module.scss';

const MessageDetail = () => {
  const { partnerId } = useParams();

  const { state: { userName = '', read = false } = {} } = useLocation();

  const {
    userDetails: { id: loggedInUserId },
  } = useContext(GlobalContext);

  // messages state
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<Message> | null>(null);
  const [error, setError] = useState<string>('');

  const [messageText, setMessageText] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);

  const [reported, setReported] = useState<boolean>(false);

  const reportUser = async () => {
    if (loggedInUserId) {
      window.analytics.track('Report User', {
        partnerId,
      });
      setReported(true);
      await reportUserService(partnerId);
    } else {
      History.push('/login?destination=' + window.location.pathname);
    }
  };

  // fetch user chat
  const fetchMessages = useCallback(async () => {
    setLoading(true);
    setError('');
    const { messages, error } = await getMessagesService(partnerId);
    if (messages) {
      setMessages(messages);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [partnerId]);

  // send messages
  const sendMessage = async () => {
    setSending(true);
    const receiver = partnerId;
    if (messageText) {
      const { message } = await sendMessageService(receiver, messageText);

      if (message) {
        fetchMessages();
        setMessageText('');
      }
    }
    setSending(false);
  };

  // mark conversation read
  const markConversationRead = useCallback(async () => {
    await markConversationReadService(partnerId);
  }, [partnerId]);

  useEffect(() => {
    fetchMessages();
    if (!read) {
      markConversationRead();
    }
  }, [fetchMessages, markConversationRead, read]);

  useEffect(() => {
    const socket = socketIOClient(BASE_URL);
    socket.emit('join', loggedInUserId);
    socket.on('message', (data) => {
      if (data.receiver === loggedInUserId && data.sender === partnerId) {
        const copy = messages ? [...messages, data] : [data];
        setMessages(copy);
      }
    });

    return () => socket.disconnect();
  }, [messages, partnerId, loggedInUserId]);

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>
                  Viewing Message From{' '}
                  <span className={styles.messageUser}>{userName}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.messageListing}>
                  <div className={styles.messageDetailsTop}>
                    <Link to={`${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}`}>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className={styles.searchIcon}
                      />
                      Back To All Messages
                    </Link>
                    <Button
                      disabled={reported}
                      label={
                        !reported
                          ? 'Report This User'
                          : 'User Has Been Reported'
                      }
                      type="outline-gray noRadius"
                      onClick={reportUser}
                    />
                  </div>
                  {loading ? <Loader /> : null}
                  {messages && messages.length > 0 && (
                    <div className={styles.chatBox}>
                      <div className={styles.chatList}>
                        <div
                          className={`${styles.list} ${
                            messages[0].sender === loggedInUserId
                              ? styles.listClient
                              : ''
                          }`}
                        >
                          <div className={styles.img}>
                            <a
                              href={`${ROUTES.PROFILE}/${messages[0].sender_slug}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={
                                  messages[0].sender_photo ||
                                  'https://placehold.co/70x70'
                                }
                                alt={messages[0].sender_name}
                              />
                            </a>
                          </div>
                          <div className={styles.messageInfo}>
                            <div className={styles.MessageHeading}>
                              {messages[0].body}
                            </div>
                            <div className={styles.user}>
                              <a
                                href={`${ROUTES.PROFILE}/${messages[0].sender_slug}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {messages[0].sender_name}
                              </a>
                            </div>
                            <div className={styles.time}>
                              {moment(messages[0].created_at).fromNow()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {messages &&
                    messages.slice(1).map((message) => {
                      if (loggedInUserId === message.sender) {
                        return (
                          <div key={message.id} className={styles.chatBox}>
                            <div className={styles.chatList}>
                              <div
                                className={`${styles.list} ${styles.listClient}`}
                              >
                                <div className={styles.img}>
                                  <a
                                    href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        message.sender_photo ||
                                        'https://placehold.co/70x70'
                                      }
                                      alt={message.sender_name}
                                    />
                                  </a>
                                </div>
                                <div className={styles.messageInfo}>
                                  <div className={styles.MessageHeading}>
                                    {message.body}
                                  </div>
                                  <div className={styles.user}>
                                    <a
                                      href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {message.sender_name}
                                    </a>
                                  </div>
                                  <div className={styles.time}>
                                    {moment(message.created_at).fromNow()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={message.id} className={styles.chatBox}>
                            <div className={styles.chatList}>
                              <div className={styles.list}>
                                <div className={styles.img}>
                                  <a
                                    href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        message.sender_photo ||
                                        'https://placehold.co/70x70'
                                      }
                                      alt={message.sender_name}
                                    />
                                  </a>
                                </div>
                                <div className={styles.messageInfo}>
                                  <div className={styles.MessageHeading}>
                                    {message.body}
                                  </div>
                                  <div className={styles.user}>
                                    <a
                                      href={`${ROUTES.PROFILE}/${message.sender_slug}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {message.sender_name}
                                    </a>
                                  </div>
                                  <div className={styles.time}>
                                    {moment(message.created_at).fromNow()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {error && <Alert variant="danger">{error}</Alert>}

                  <div className={styles.chatBox}>
                    <div className={styles.chatList}></div>
                    <div className={styles.textAreaOuter}>
                      <TextArea
                        placeholder="Write a message"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                      />
                    </div>
                    <div className={styles.btnOuter}>
                      <Button
                        label={sending ? 'loading...' : 'Send Message'}
                        type="branding large"
                        disabled={sending}
                        onClick={sendMessage}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default MessageDetail;
