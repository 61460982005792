import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

// Components
import Input from 'components/Form/Input/Input';
import Select from 'components/Form/Select/Select';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import TextArea from 'components/Form/TextArea/TextArea';
import Button from 'components/Button/Button';
import FileUpload from 'components/FileUpload/FileUpload';

import { Multiselect } from 'multiselect-react-dropdown';

import PaymentForm from 'components/PaymentForm/PaymentForm';

// Interfaces
import { CareerForm, CareerFormErrors } from 'interfaces/CareerForm.types';
import { Job } from 'interfaces/Job.types';
import { Category } from 'interfaces/Category.types';

// Services
import { createJobService, updateJobService } from 'services/JobService';

import {
  getUploadURLService,
  verifyCouponService,
} from 'services/CommonService';

// Utilities
import { camelToSnake } from 'utils/UtilityFunctions';

// Constants
import STATES from 'constants/States';
import INDUSTRIES from 'config/Industries';

// Text
import COPY_OVERRIDES from 'config/CopyOverrides';

// import DEADLINES from '../../constants/Deadlines';
import CAREER_CONFIG from 'config/CreateCareerConfig';
import CAREER_RULES from '../../constants/CareerRules';
import {
  DEFAULT_CAREER_ERRORS,
  DEFAULT_CAREER_VALUES,
} from '../../constants/DefaultValues';
import FIELD_LABELS from '../../constants/FieldLabels';
import { APP_CONSTANTS, FILE_TYPE, ROUTES } from 'constants/Constants';

// SCSS
import styles from './Career.module.scss';

interface CouponResponse {
  id: string;
  amount: number;
}

interface PostProps {
  id: string;
  job: Job;
  categories: Array<Category>;
  relist: boolean;
}

const Post: React.FC<PostProps> = ({
  id: postId,
  job,
  categories,
  relist,
}: PostProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [logo, setLogo] = useState<File | null>(null);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [code, setCode] = useState<string>('');
  const [coupon, setCoupon] = useState<CouponResponse | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [couponError, setCouponError] = useState<string>('');

  const [career, setCareer] = useState<CareerForm>(DEFAULT_CAREER_VALUES);

  const [careerFormErrors, setCareerFormErrors] = useState<CareerFormErrors>(
    DEFAULT_CAREER_ERRORS
  );

  const {
    errorConstants: { SOMETHING_WENT_WRONG: serverError },
    careerFeaturedAmount: featuredAmount,
  } = APP_CONSTANTS;

  const fileSpecs = ['5mb max', '500px wide', '500px tall'];

  useEffect(() => {
    if (job) {
      const obj: any = {};
      Object.keys(DEFAULT_CAREER_VALUES).map((key: string) => {
        obj[key] = job[camelToSnake(key)];
        return key;
      });
      setCareer(obj);
      if (obj.isFeatured && relist) {
        setAmount(featuredAmount);
      }
    }
  }, [job, featuredAmount, relist]);

  const validateField = (target: any) => {
    const { name, value, type, checked } = target;
    if (type === 'checkbox') {
      if (!checked) {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: '',
        });
      }
    } else {
      if (name === 'pay' && value < 0) {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: `${FIELD_LABELS[name]} can either be blank (non-paying) or greater than 0`,
        });
      } else if (
        name === 'externalUrl' &&
        !value &&
        career['applyThrough'] === 'external'
      ) {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else if (CAREER_RULES[name]?.required && !value) {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else if (value) {
        if (
          CAREER_RULES[name]?.minLength &&
          value.toString().length < CAREER_RULES[name]?.minLength
        ) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} should contain at least ${CAREER_RULES[name]?.minLength} characters`,
          });
        } else if (
          CAREER_RULES[name]?.maxLength &&
          value.toString().length > CAREER_RULES[name]?.maxLength
        ) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} can not be longer than ${CAREER_RULES[name]?.maxLength} characters`,
          });
        } else if (CAREER_RULES[name]?.min && value < CAREER_RULES[name]?.min) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} should be greater than ${CAREER_RULES[name].min}`,
          });
        } else if (CAREER_RULES[name]?.max && value < CAREER_RULES[name]?.max) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} should be lesser than ${CAREER_RULES[name]?.max}`,
          });
        } else if (
          CAREER_RULES[name]?.pattern &&
          !CAREER_RULES[name]?.pattern.test(value)
        ) {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: `${FIELD_LABELS[name]} is invalid`,
          });
        } else {
          setCareerFormErrors({
            ...careerFormErrors,
            [name]: '',
          });
        }
      } else {
        setCareerFormErrors({
          ...careerFormErrors,
          [name]: '',
        });
      }
    }
    return;
  };

  const handleChange = (event: any) => {
    if (jobId) {
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    if (couponError) {
      setCouponError('');
    }
    const { name, value, type, checked } = event.target;
    if (type !== 'checkbox') {
      setCareer({
        ...career,
        [name]: name === 'pay' && value ? parseFloat(value) : value,
      });
    } else {
      if (name === 'applyThrough' && value === 'site') {
        setCareer({
          ...career,
          [name]: checked ? value : '',
          externalUrl: '',
        });
      } else {
        setCareer({
          ...career,
          [name]: checked ? value : '',
        });
      }
    }
    validateField(event.target);
  };

  const handleCodeChange = (event: any) => {
    setCouponError('');
    setCode(event.target.value);
  };

  const handleCategorySelect = (categoryList: Array<Category | any>) => {
    setCareer({
      ...career,
      category: categoryList,
    });

    if (!categoryList.length) {
      setCareerFormErrors({
        ...careerFormErrors,
        category: 'Category is required',
      });
    } else {
      setCareerFormErrors({
        ...careerFormErrors,
        category: '',
      });
    }
  };

  const handleFeatured = (val: boolean) => {
    if (jobId) {
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    if (couponError) {
      setCouponError('');
    }
    setCareer({
      ...career,
      isFeatured: val,
    });
    setCareerFormErrors({
      ...careerFormErrors,
      isFeatured: '',
    });
    setAmount(val ? featuredAmount : 0);
    setCoupon(null);
    setCode('');
  };

  const uploadLogo = async () => {
    let uploadImageResponse: any = null;
    const fileInfo = {
      name: logo?.name,
      type: logo?.type,
    };
    const { signedRequest, url, error } = await getUploadURLService(fileInfo);

    if (signedRequest && url) {
      uploadImageResponse = await fetch(signedRequest, {
        method: 'PUT',
        body: logo,
      });

      if (uploadImageResponse) {
        return url;
      }
    } else if (error) {
      setApiError(`An error occurred while uploading logo: ${error}`);
      setIsLoading(false);
      return null;
    }
  };

  const verifyCouponCode = async () => {
    if (coupon) {
      setCode('');
      setCoupon(null);
      setAmount(featuredAmount);
      return;
    }
    if (!code) {
      return;
    }
    setCouponError('');
    setIsLoading(true);
    const { id, type, error } = await verifyCouponService(code);

    if (id) {
      setCoupon({
        id,
        amount: type === 'full' ? featuredAmount : featuredAmount / 2,
      });

      setAmount(type === 'full' ? 0 : featuredAmount / 2);

      setIsLoading(false);
    } else if (error) {
      setCouponError(error);
      setIsLoading(false);
    }
  };

  const validateFields = () => {
    if (jobId) {
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    if (couponError) {
      setCouponError('');
    }
    const errors = Object.create({});
    Object.keys(careerFormErrors).map((name) => {
      if (name === 'pay' && career[name] < 0) {
        errors[
          name
        ] = `${FIELD_LABELS[name]} can either be blank (non-paying) or greater than 0`;
      } else if (name === 'category' && !career[name].length) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (
        name === 'externalUrl' &&
        !career[name] &&
        career['applyThrough'] === 'external'
      ) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (
        name === 'isFeatured' &&
        CAREER_RULES[name]?.required &&
        career[name] === null
      ) {
        errors[name] = 'Please choose if you would like your post featured';
      } else if (
        CAREER_RULES[name]?.required &&
        !career[name] &&
        name !== 'isFeatured'
      ) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (career[name] && name !== 'isFeatured') {
        if (
          CAREER_RULES[name]?.minLength &&
          career[name].toString().length < CAREER_RULES[name]?.minLength
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should contain at least ${CAREER_RULES[name]?.minLength} characters`;
        } else if (
          CAREER_RULES[name]?.maxLength &&
          career[name].toString().length > CAREER_RULES[name]?.maxLength
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} can not be longer than ${CAREER_RULES[name]?.maxLength} characters`;
        } else if (
          CAREER_RULES[name]?.min &&
          career[name] < CAREER_RULES[name]?.min
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should be greater than ${CAREER_RULES[name]?.min}`;
        } else if (
          CAREER_RULES[name]?.max &&
          career[name] < CAREER_RULES[name]?.max
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should be lesser than ${CAREER_RULES[name]?.max}`;
        } else if (
          CAREER_RULES[name]?.pattern &&
          !CAREER_RULES[name]?.pattern.test(career[name])
        ) {
          errors[name] = `${FIELD_LABELS[name]} is invalid`;
        }
      }
      return name;
    });
    if (Object.keys(errors).length) {
      setCareerFormErrors({
        ...careerFormErrors,
        ...errors,
      });
      setApiError('There are some errors above, please fix those to proceed.');
      return false;
    }
    return true;
  };

  const callAnalytics = () => {
    window.analytics.track('Posted Career', {
      careerTitle: career.title,
      category: career.category,
      companyName: career.companyName,
      pay: career.pay,
      compensation: career.pay,
      rate: career.rate,
      duration: career.expectedNeed,
      workLocation: career.location,
      company: career.companyName,
      city: career.city,
      state: career.state,
      jobDescription: career.opportunityDescription,
      companyDescription: career.hiringDescription,
      featureJob: career.isFeatured,
      industry: career.industry,
    });
  };

  const createJob = async (careerFormData: any) => {
    const response = await createJobService(careerFormData);

    if (!response.ok) {
      const error = await response.clone().text();
      setApiError(error || serverError);
    } else {
      const { id } = await response.json();
      callAnalytics();
      setJobId(id);
      setCareer(DEFAULT_CAREER_VALUES);
    }

    setIsLoading(false);
  };

  const updateJob = async (careerFormData: any) => {
    const { id, error } = await updateJobService(postId, careerFormData);

    if (error) {
      setApiError(error || serverError);
    } else {
      setJobId(id);
    }

    setIsLoading(false);
  };

  // Todo Validate file specs

  /**
   * Create a career
   */
  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    if (amount && !selectedCard) {
      setApiError('Please select/add a card to proceed with the payment');
      return;
    }

    setApiError('');
    setJobId('');
    setIsLoading(true);

    let uploadResponse = '';
    if (logo) {
      uploadResponse = await uploadLogo();
      if (!uploadResponse) {
        return;
      }
    }

    const careerFormData: any = {};
    Object.keys(career).map((key: string) => {
      careerFormData[camelToSnake(key)] = career[key];
      return key;
    });
    careerFormData['company_logo'] =
      uploadResponse || (job && job.company_logo ? job.company_logo : '');
    if (!careerFormData.pay) {
      careerFormData.pay = null;
    }
    if (careerFormData.is_featured && (!postId || relist)) {
      if (amount) {
        careerFormData.cardId = selectedCard;
      }
      if (coupon) {
        careerFormData.coupon_id = coupon.id;
      }
    }
    careerFormData.fee = null;
    careerFormData.reference_links = null;
    careerFormData.type = 'career';
    careerFormData.category = career.category
      .map((item) => item.name)
      .join(',');

    if (!postId || relist) {
      createJob(careerFormData);
    } else {
      delete careerFormData['is_featured'];
      updateJob(careerFormData);
    }
  };

  const renderTooltipCom = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      What will you pay someone? Can be &quot;$0&quot;
    </Tooltip>
  );

  const renderTooltipRate = (props: any) => (
    <Tooltip id="button-tooltipRate" {...props}>
      {CAREER_CONFIG?.rate?.info}
    </Tooltip>
  );

  const renderTooltipLocation = (props: any) => (
    <Tooltip id="button-tooltipLocation" {...props}>
      {CAREER_CONFIG?.location?.info}
    </Tooltip>
  );

  return (
    <Row className={styles.careerType}>
      {(!CAREER_CONFIG?.title || !CAREER_CONFIG?.title?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.title?.label || 'Career Post Title'}{' '}
            <span>(max 100 characters)</span>
            <span className={styles.requiredField}> *</span>
            <div className={`${styles.requiredFieldsNote} text-right`}>
              <span>* </span>= Required Fields
            </div>
          </div>
          <Input
            type="text"
            onChange={handleChange}
            value={career.title}
            name="title"
            className={careerFormErrors.title ? styles.errorInput : ''}
          />
          {careerFormErrors.title && (
            <div className={styles.error}>{careerFormErrors.title}</div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.category || !CAREER_CONFIG?.category?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.category?.label || 'Category'}{' '}
            <a
              href={
                COPY_OVERRIDES?.profile?.submit_category_link ||
                'https://musiccareermap.com/category-submission'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    COPY_OVERRIDES?.profile?.submit_category_text ||
                    'If you don&apos;t see your category listed, click here to submit it.',
                }}
              ></span>
            </a>
            <span className={styles.requiredField}> *</span>
          </div>
          <Multiselect
            options={categories || []}
            isObject={true}
            displayValue="name"
            selectedValues={career.category}
            singleSelect={true}
            showCheckbox
            onSelect={handleCategorySelect}
            onRemove={handleCategorySelect}
          />
          {careerFormErrors.category && (
            <div className={styles.error}>{careerFormErrors.category}</div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.pay || !CAREER_CONFIG?.pay?.hidden) && (
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.pay?.label || 'Compensation'}
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipCom}
            >
              <div className={styles.infoHover}>i</div>
            </OverlayTrigger>
          </div>
          <Input
            type="number"
            placeholder="$"
            onChange={handleChange}
            value={career.pay}
            name="pay"
          />
          {careerFormErrors.pay && (
            <div className={styles.error}>{careerFormErrors.pay}</div>
          )}
        </Col>
      )}

      {!!INDUSTRIES?.length && (
        <Col xl={6} lg={6} md={6} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.industry?.label || 'Industry'}
            <span className={styles.requiredField}> *</span>
          </div>
          <Select
            id="industry"
            selectItems={INDUSTRIES}
            onChange={handleChange}
            name="industry"
            value={career.industry}
          />
          {careerFormErrors.industry && (
            <div className={styles.error}>{careerFormErrors.industry}</div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.rate || !CAREER_CONFIG?.rate?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.rate?.label || 'Rate'}
            <span className={styles.requiredField}> *</span>
            {CAREER_CONFIG?.rate?.info && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipRate}
              >
                <div className={styles.infoHover}>i</div>
              </OverlayTrigger>
            )}
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="free"
              name="rate"
              isChecked={career.rate === 'free'}
              onChange={handleChange}
              label="Non-Paying"
              value="free"
            />
            <Checkbox
              id="perHour"
              name="rate"
              isChecked={career.rate === 'hour'}
              onChange={handleChange}
              label="Per Hour"
              value="hour"
            />
            <Checkbox
              id="flatFee"
              name="rate"
              isChecked={career.rate === 'flat'}
              onChange={handleChange}
              label="Flat Fee"
              value="flat"
            />
            <Checkbox
              id="salary"
              name="rate"
              isChecked={career.rate === 'salary'}
              onChange={handleChange}
              label="Salary"
              value="salary"
            />
            <Checkbox
              id="TBD"
              name="rate"
              isChecked={career.rate === 'TBD'}
              onChange={handleChange}
              label="TBD"
              value="TBD"
            />
          </div>
          {careerFormErrors.rate && (
            <div className={styles.error}>{careerFormErrors.rate}</div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.expectedNeed ||
        !CAREER_CONFIG?.expectedNeed?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.expectedNeed?.label || 'Position'}
            <span className={styles.requiredField}> *</span>
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="fullTime"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'full time'}
              onChange={handleChange}
              label="Full Time"
              value="full time"
            />
            <Checkbox
              id="partTime"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'part time'}
              onChange={handleChange}
              label="Part Time"
              value="part time"
            />
            <Checkbox
              id="oneTime"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'one time'}
              onChange={handleChange}
              label="One Time"
              value="one time"
            />
            <Checkbox
              id="freelance"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'freelance'}
              onChange={handleChange}
              label="Freelance"
              value="freelance"
            />
            <Checkbox
              id="internship"
              name="expectedNeed"
              isChecked={career.expectedNeed === 'internship'}
              onChange={handleChange}
              label="Internship"
              value="internship"
            />
            {/* <Checkbox
            id="salary1"
            name="expectedNeed"
            isChecked={career.expectedNeed === 'salary'}
            onChange={handleChange}
            label="Salary"
            value="salary"
          /> */}
          </div>
          {careerFormErrors.expectedNeed && (
            <div className={styles.error}>{careerFormErrors.expectedNeed}</div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.location || !CAREER_CONFIG?.location?.hidden) && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.location?.label || 'Location'}
            <span className={styles.requiredField}> *</span>
            {CAREER_CONFIG?.location?.info && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipLocation}
              >
                <div className={styles.infoHover}>i</div>
              </OverlayTrigger>
            )}
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="onSite"
              name="location"
              isChecked={career.location === 'on site'}
              onChange={handleChange}
              label="On-Site"
              value="on site"
            />
            <Checkbox
              id="remote"
              name="location"
              isChecked={career.location === 'remote'}
              onChange={handleChange}
              label="Remote"
              value="remote"
            />
          </div>
          {careerFormErrors.location && (
            <div className={styles.error}>{careerFormErrors.location}</div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.companyName || !CAREER_CONFIG?.companyName?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.doubleInput}>
            <div className={styles.inputLabel}>
              {CAREER_CONFIG?.companyName?.label ||
                'Hiring Company/Individual Name'}
              <span className={styles.requiredField}> *</span>
            </div>
            <Input
              type="text"
              onChange={handleChange}
              value={career.companyName}
              name="companyName"
            />
            {careerFormErrors.companyName && (
              <div className={styles.error}>{careerFormErrors.companyName}</div>
            )}
          </div>
          {/* <div className={styles.doubleInput}>
          <div className={styles.inputLabel}>
            Project Deadline <span>(if any)</span>
          </div>
          <Select
            id="deadline"
            selectItems={DEADLINES}
            onChange={handleChange}
            name="deadline"
            value={career.deadline}
          />
          {careerFormErrors.deadline && (
            <div className={styles.error}>{careerFormErrors.deadline}</div>
          )}
        </div> */}
        </Col>
      )}

      {(!CAREER_CONFIG?.logo || !CAREER_CONFIG?.logo?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.logo?.label || 'Company Logo/Image'}
          </div>
          <FileUpload
            fileType={FILE_TYPE.IMAGE}
            fileSpecs={fileSpecs}
            onFileChange={setLogo}
            image={job?.company_logo}
            reset={!!jobId && !postId}
          />
          {careerFormErrors.companyLogo && (
            <div className={styles.error}>{careerFormErrors.companyLogo}</div>
          )}
        </Col>
      )}

      <Col xl={12} lg={12} md={12} sm={12}>
        <Row>
          {(!CAREER_CONFIG?.city || !CAREER_CONFIG?.city?.hidden) && (
            <Col xl={6} lg={6} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {CAREER_CONFIG?.city?.label || 'City'}
                <span className={styles.requiredField}> *</span>
              </div>
              <Input
                type="text"
                onChange={handleChange}
                value={career.city}
                name="city"
              />
              {careerFormErrors.city && (
                <div className={styles.error}>{careerFormErrors.city}</div>
              )}
            </Col>
          )}
          {(!CAREER_CONFIG?.state || !CAREER_CONFIG?.state?.hidden) && (
            <Col xl={3} lg={3} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {CAREER_CONFIG?.state?.label || 'State'}
                <span className={styles.requiredField}> *</span>
              </div>
              <Select
                id="state"
                selectItems={STATES}
                onChange={handleChange}
                name="state"
                value={career.state}
              />
              {careerFormErrors.state && (
                <div className={styles.error}>{careerFormErrors.state}</div>
              )}
            </Col>
          )}
          {(!CAREER_CONFIG?.zip || !CAREER_CONFIG?.zip?.hidden) && (
            <Col xl={3} lg={3} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {CAREER_CONFIG?.zip?.label || 'Zip'}
                <span className={styles.requiredField}> *</span>
              </div>
              <Input
                type="text"
                onChange={handleChange}
                value={career.zip}
                name="zip"
              />
              {careerFormErrors.zip && (
                <div className={styles.error}>{careerFormErrors.zip}</div>
              )}
            </Col>
          )}
        </Row>
      </Col>

      {(!CAREER_CONFIG?.opportunityDescription ||
        !CAREER_CONFIG?.opportunityDescription?.hidden) && (
        <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.opportunityDescription?.label ||
              'Career Description'}
            <span className={styles.requiredField}> *</span>
          </div>
          <TextArea
            onChange={handleChange}
            value={career.opportunityDescription}
            name="opportunityDescription"
          />
          {careerFormErrors.opportunityDescription && (
            <div className={styles.error}>
              {careerFormErrors.opportunityDescription}
            </div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.hiringDescription ||
        !CAREER_CONFIG?.hiringDescription?.hidden) && (
        <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
          <div className={styles.inputLabel}>
            {CAREER_CONFIG?.hiringDescription?.label ||
              'Hiring Company/Individual Description'}
            <span className={styles.requiredField}> *</span>
          </div>
          <TextArea
            onChange={handleChange}
            value={career.hiringDescription}
            name="hiringDescription"
          />
          {careerFormErrors.hiringDescription && (
            <div className={styles.error}>
              {careerFormErrors.hiringDescription}
            </div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.applyThrough ||
        !CAREER_CONFIG?.applyThrough?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            How To Apply
            <span className={styles.requiredField}> *</span>
          </div>
          <div className={styles.checkRow}>
            <Checkbox
              id="onThisSite"
              name="applyThrough"
              isChecked={career.applyThrough === 'site'}
              onChange={handleChange}
              label="On This Site"
              value="site"
            />
            <Checkbox
              id="externalURL"
              name="applyThrough"
              isChecked={career.applyThrough === 'external'}
              onChange={handleChange}
              label="External URL"
              value="external"
            />
          </div>
          {careerFormErrors.applyThrough && (
            <div className={styles.error}>{careerFormErrors.applyThrough}</div>
          )}
        </Col>
      )}

      {(!CAREER_CONFIG?.applyThrough ||
        !CAREER_CONFIG?.applyThrough?.hidden) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>External URL</div>
          <Input
            type="text"
            placeholder="http://"
            onChange={handleChange}
            value={career.externalUrl}
            name="externalUrl"
            disabled={career.applyThrough !== 'external'}
          />
          {careerFormErrors.externalUrl && (
            <div className={styles.error}>{careerFormErrors.externalUrl}</div>
          )}
        </Col>
      )}

      <div className={styles.formDivider} />

      {(!postId || relist) && (
        <Col xl={6} lg={6} md={12} sm={12}>
          <div className={styles.inputLabel}>
            Featured Listing?
            <span className={styles.requiredField}> *</span>
          </div>
          <div className={styles.featureDesc}>
            Featured listings gain visibility through top placement for category
            listings, in addition to receiving a dedicated email campaign to our
            applicant community and an account representative. Featured Listings
            remain for 30-days from posting and can be renewed in your dashboard
            with a single click.
          </div>
          <div className={styles.featureList}>
            <ul>
              <li>Account Representative</li>
              <li>Top Listing</li>
              <li>Email Campaign</li>
            </ul>
          </div>
          {careerFormErrors.isFeatured && (
            <div className={styles.error}>{careerFormErrors.isFeatured}</div>
          )}
        </Col>
      )}

      {(!postId || relist) && (
        <Col className={styles.limitedPriceData} xl={6} lg={6} md={12} sm={12}>
          <div className={styles.limitedPricing}>Limited Time Pricing</div>
          <div className={styles.limitedPrice}>
            <div className={styles.prices}>
              <s>${featuredAmount + 10}</s> &nbsp; <b>${featuredAmount}</b>
            </div>
          </div>
          <div className={styles.limitedButtons}>
            <Button
              onClick={() => handleFeatured(false)}
              type="gray my-2"
              label={`${
                career.isFeatured === false ? '✔ ' : ''
              }Don't Feature My Post`}
            />
            <br />
            <Button
              onClick={() => handleFeatured(true)}
              type="gradient-blue"
              label={`${career.isFeatured ? '✔ ' : ''}Feature My Post`}
            />
          </div>
        </Col>
      )}

      <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Questions?
          <a href={ROUTES.SUPPORT} target="_blank" rel="noopener noreferrer">
            {' '}
            Contact us
          </a>
        </div>
      </Col>

      {(!postId || relist) && career.isFeatured && (
        <>
          <div className={styles.formDivider}></div>
          <Col xl={12} lg={12} md={12} sm={12} className={styles.posRel}>
            <Input
              type="text"
              placeholder="Coupon Code"
              onChange={handleCodeChange}
              value={code}
              name="code"
            />
            <Button
              onClick={verifyCouponCode}
              buttonType="button"
              label={!coupon ? 'Apply' : 'Remove'}
              type={`branding ${styles.applyCouponBtn}`}
              disabled={isLoading || !code}
              isLoading={isLoading}
            />
          </Col>
          {couponError && (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.jobSubmitError}>
                <Alert variant={'danger'} className={styles.apiError}>
                  {couponError}
                </Alert>
              </div>
            </Col>
          )}
          {amount > 0 ? (
            <>
              <Col xl={12} lg={12} md={12} sm={12} className="mt-3">
                <div className={styles.inputLabel}>
                  {coupon
                    ? `Applied coupon worth $${coupon.amount.toFixed(2)}. `
                    : ''}{' '}
                  Please provide payment details to pay ${amount.toFixed(2)}.
                </div>
              </Col>
              <PaymentForm setSelectedCard={setSelectedCard} />
            </>
          ) : (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {coupon ? `Applied coupon worth $${coupon.amount}. ` : ''}
              </div>
            </Col>
          )}
        </>
      )}
      {apiError && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.jobSubmitError}>
            <Alert variant={'danger'} className={styles.apiError}>
              {apiError}
            </Alert>
          </div>
        </Col>
      )}
      <Col xl={12} lg={12} md={12} sm={12}>
        <Button
          onClick={handleSubmit}
          buttonType="button"
          type="large mt-5 py-3 w-100"
          label={postId && !relist ? 'Update Post' : 'Create Post'}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </Col>
      {jobId && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.jobSubmitSuccess}>
            <Redirect to={`${ROUTES.CAREER}/${jobId}`} />
            {/* {!postId ? (
              <Redirect to={`${ROUTES.CAREER}/${jobId}`} />
            ) : (
              <Redirect to={`${ROUTES.CAREER}/${jobId}`} />
            )} */}
          </div>
        </Col>
      )}
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.subText}>
          Posts will last 30 days, you can refresh a post via your dashboard if
          you wish to continue the search.
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(Post);
