import React, { useState, useEffect, useCallback } from 'react';
import GlobalContext from '../contexts/Global.context';
import jwt from 'jwt-decode';
import History from '../utils/History';

const GlobalState: React.FC = (props: any) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token')
  );
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});

  const logout = useCallback(() => {
    setLoggedIn(false);
    localStorage.removeItem('token');
    History.push('/');
    setUserDetails({});
  }, []);

  const setAuth = (newToken: string | null) => {
    if (newToken) {
      localStorage.setItem('token', newToken);
      setLoggedIn(true);
      setToken(newToken);
      const userDetails = jwt(newToken);
      setUserDetails(userDetails);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setAuth(localStorage.getItem('token'));
    }
  }, [token]);

  const wrap = {
    isLoggedIn: isLoggedIn,
    isLoading: isLoading,
    logout: logout,
    userDetails,
    setAuth: setAuth,
    setLoader: setIsLoading,
  };

  return (
    <GlobalContext.Provider value={wrap}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
